import RequestUtils from "../utils/RequestUtils";
import GuestNoteRes from "../types/res/guestnote/GuestNoteRes";
import UpdateGuestNoteReq from "types/req/guestNote/UpdateGuestNoteReq";

export default class GuestNoteApi {
  static async fetchGuestNotes(
    companyId: number,
    startDate: string,
    endDate: string
  ) {
    return await RequestUtils.getArray(
      GuestNoteRes,
      `/guestNote/findAll/${companyId}/${startDate}/${endDate}`
    );
  }

  static async fetchOneGuestNote(companyId: number, guestNoteId: number) {
    return await RequestUtils.get(
      GuestNoteRes,
      `/guestNote/findOne/${companyId}/${guestNoteId}`
    );
  }
  static async putGuestNote(companyId: number, req: UpdateGuestNoteReq) {
    return await RequestUtils.post(
      GuestNoteRes,
      `/guestNote/update/${companyId}`,
      req
    );
  }
}
