import React from "react";
import { Link } from "react-router-dom";

import Helmet from "react-helmet";

import { Typography } from "@material-ui/core";
import { Wrapper, Button } from "./components";

function Page404() {
  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        mt={2}
        component={Link}
        to="/"
      >
        Return to website
      </Button>
    </Wrapper>
  );
}

export default Page404;
