import React from "react";
import {
  Appointments,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import DateTimeUtils from "utils/DateTimeUtils";
import styled from "styled-components";
import { Order as OrderType } from "redux/reducers/orders";
import { Box, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import EnumUtils from "utils/EnumUtils";
import OrderStatus from "types/enum/OrderStatus";

const Bold = styled.span`
  font-weight: bold;
`;

const ScheduleItem = ({
  title,
  content,
}: {
  title: string;
  content: string;
}) => {
  return (
    <div>
      <Bold>{title}:</Bold> {content}
    </div>
  );
};

const MainItems = ({ data }: { data: OrderType }) => {
  return (
    <>
      <Box display="flex">
        <ScheduleItem title="顧客名" content={data?.guest.name || "未設定"} />
      </Box>
      <ScheduleItem
        title="出発"
        content={
          data.departureTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.departureTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定IN"
        content={
          data.planInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planInTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="予定OUT"
        content={
          data.planOutTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.planOutTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="実IN"
        content={
          data.actualInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.actualInTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem
        title="実OUT"
        content={
          data.actualEndTime
            ? DateTimeUtils.toFormatAsLocalTimezone(data.actualEndTime, "HH:mm")
            : "未設定"
        }
      />
      <ScheduleItem title="店舗名" content={data.shop.name || ""} />
      <ScheduleItem title="コース" content={data.course.name || ""} />
      <ScheduleItem title="住所1" content={`${data.orderAddress || ""}`} />
      <ScheduleItem title="住所2" content={`${data.orderAddress2 || ""}`} />
      <ScheduleItem
        title="送り"
        content={`${data.outwardDriver?.carType || ""} ${
          data.outwardDriver?.carColor || ""
        } ${data.outwardDriver?.carNumber || ""}`}
      />
      <ScheduleItem
        title="迎え"
        content={`${data.returnDriver?.carType || ""} ${
          data.returnDriver?.carColor || ""
        } ${data.returnDriver?.carNumber || ""}`}
      />
      <ScheduleItem title="エリア" content={`${data.area?.name || ""}`} />
      <ScheduleItem title="ホテル" content={`${data.hotel?.name || ""}`} />
      <ScheduleItem title="総額" content={`${data.totalFee || 0}円`} />
    </>
  );
};

export const AppointmentContent = ({ children, data, ...restProps }: any) => {
  if (data?.orderId) {
    return (
      <Appointments.Appointment
        data={data}
        {...restProps}
        style={{
          fontSize: 14,
          backgroundColor:
            EnumUtils.mapToEnum(OrderStatus, data.status) ===
            OrderStatus.castRequest
              ? "#FCDFDF"
              : EnumUtils.mapToEnum(OrderStatus, data.status) ===
                OrderStatus.castConfirm
              ? "#C1F1CF"
              : "inherit",
        }}
      >
        <div>
          <MainItems data={data} />
        </div>
      </Appointments.Appointment>
    );
  } else {
    return (
      <Appointments.Appointment
        data={data}
        {...restProps}
        style={{ fontSize: 14, backgroundColor: "gray" }}
      >
        <div>
          <Typography
            style={{ fontSize: "12px", color: "white", padding: "8px" }}
          >
            {data.title}
          </Typography>
        </div>
      </Appointments.Appointment>
    );
  }
};

export const AppointmentTooltipContent = ({
  appointmentData,
  formatDate,
  children,
  ...restProps
}: any) => {
  const { options, cosplay, totalCastFee, memo, guest } = appointmentData;
  if (!appointmentData?.orderId) return <></>;
  return (
    <AppointmentTooltip.Content
      appointmentData={appointmentData}
      {...restProps}
      formatDate={formatDate}
    >
      <MainItems data={appointmentData as OrderType} />
      <ScheduleItem title="バック" content={`${totalCastFee}円`} />
      <ScheduleItem
        title="オプション"
        content={options.map((option: any) => option.name || "").join("/")}
      />
      <ScheduleItem title="コスプレ" content={cosplay?.name || ""} />
      <ScheduleItem title="備考" content={memo} />
      <Box display="flex">
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={`/guestNote/${appointmentData.guestNoteId}`}
        >
          お客様ノートへ
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          style={{ marginLeft: "10px" }}
          to={`/inout/${appointmentData.orderId}`}
        >
          予約詳細
        </Button>
      </Box>
    </AppointmentTooltip.Content>
  );
};
