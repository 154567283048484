import { Exclude, Expose, Type } from "class-transformer";
import GuestRes from "types/res/guest/GuestRes";
import ShopRes from "types/res/shop/ShopRes";
import GuestCategoryRes from "types/res/guestCategory/GuestCategoryRes";

export default class GuestGuestCategoryShopRes {
  @Exclude() readonly guestGuestCategoryShopId!: number;
  @Expose() readonly guestId!: number;
  @Expose() readonly shopId!: number;
  @Expose() readonly guestCategoryId!: number;

  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
  @Type(() => GuestCategoryRes)
  @Expose()
  readonly guestCategory!: GuestCategoryRes;
}
