import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { fetchShops } from "redux/actions/shops";
import { fetchOneGuest } from "redux/actions/guest";
import { fetchCourse } from "redux/actions/course";
import { fetchNomination } from "redux/actions/nomination";
import { fetchAdditionalTime } from "redux/actions/additionalTime";
import { fetchOptionByCastNameId } from "redux/actions/option";
import { fetchCosplay } from "redux/actions/cosplay";
import { fetchArea } from "redux/actions/area";
import { fetchHotel } from "redux/actions/hotel";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  Select,
  InputLabel,
  Checkbox,
  ListItemText,
  Button,
  Icon,
} from "@material-ui/core";
import CreateOrderReq from "types/req/order/order/CreateOrderReq";
import CourseRes from "types/res/course/CourseRes";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import { Autocomplete } from "@material-ui/lab";
import HotelRes from "types/res/hotel/HotelRes";
import AreaRes from "types/res/area/AreaRes";
import PaymentType from "types/enum/PaymentType";
import SettingBookEmailRes from "types/res/settingBookEmail/SettingBookEmailRes";
import DriverSettingBookEmailRes from "types/res/driverSettingBookEmail/DriverSettingBookEmailRes";
import SettingBookEmailApi from "api/SettingBookEmailApi";
import DriverSettingBookEmailApi from "api/DriverSettingBookEmailApi";
import EnumUtils from "utils/EnumUtils";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { createOrder } from "redux/actions/orders";
import { DateTime } from "luxon";
import { fetchNotelClassByCastNameId } from "redux/actions/notelClass";
import OrderStatus from "types/enum/OrderStatus";
import { Add, Delete } from "@material-ui/icons";
const NewOrder = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.cast.companyId);
  const cast = useSelector((state) => state.account.cast);
  const shops = useSelector((state) => state.shops);
  const guest = useSelector((state) => state.guest);
  const courses = useSelector((state) => state.course);
  const nominations = useSelector((state) => state.nomination);
  const additionalTimes = useSelector((state) => state.additionalTime);
  const options = useSelector((state) => state.option);
  const cosplays = useSelector((state) => state.cosplay);
  const areas = useSelector((state) => state.area);
  const hotels = useSelector((state) => state.hotel);
  const notelClass = useSelector((state) => state.notelClass);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>({} as CreateOrderReq);
  const [filterCourses, setFilterCourses] = useState<CourseRes[]>([]);
  const [filterAdditionalTimes, setFilterAdditionalTimes] = useState<
    AdditionalTimeRes[]
  >([]);
  const [filterHotels, setFilterHotels] = useState<HotelRes[]>([]);
  const [filterAreas, setFilterAreas] = useState<AreaRes[]>([]);
  const [selectArea, setSelectArea] = useState<AreaRes>();
  const [selectHotel, setSelectHotel] = useState<HotelRes>();
  const [isConfirm, setIsConfirm] = useState(false);
  const [settingBookEmail, setSettingBookEmail] = useState<
    SettingBookEmailRes
  >();
  const [bookEmailBody, setBookEmailBody] = useState("");
  const [driverSettingBookEmail, setDriverSettingBookEmail] = useState<
    DriverSettingBookEmailRes
  >();
  const [driverBookEmailBody, setDriverBookEmailBody] = useState("");

  const history = useHistory();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const date = query.get("date");
  const shopId = query.get("shopId");
  const guestId = query.get("guestId");
  const time = query.get("time");
  const castName = cast.castNames.find(
    (castName) => castName.shopId === Number(shopId)
  );

  useEffect(() => {
    if (!date || !shopId || !guestId || !time) {
      alert("URLが不正です。お客様ノート画面に戻ります。");
      return history.push("/guestNoteList");
    }
    const hours = DateTime.fromFormat(time, FORMAT_TYPE.TIME).hour;
    const minutes = DateTime.fromFormat(time, FORMAT_TYPE.TIME).minute;
    setFormData({
      departureTime: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY)
        .plus({ hours, minutes })
        .toJSDate(),
      planInTime: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY)
        .plus({ hours, minutes })
        .toJSDate(),
      shopId: Number(shopId),
      guestId: Number(guestId),
      orderDate: date,
      castNameId: castName?.castNameId,
      notelClassId: castName?.notelClassId,
      additionalTimeOrders: [{ additionalTimeId: null, count: 0 }],
      status: "castRequest" as OrderStatus,
    });

    const apiCall = async () => {
      if (!castName) return;
      setIsLoading(true);
      await dispatch(fetchShops({ companyId }));
      await dispatch(fetchOneGuest({ companyId, guestId: Number(guestId) }));
      await dispatch(fetchCourse({ companyId, shopId: Number(shopId) }));
      await dispatch(fetchNomination({ companyId }));
      await dispatch(
        fetchAdditionalTime({ companyId, shopId: Number(shopId) })
      );
      if (castName) {
        await dispatch(
          fetchOptionByCastNameId({
            companyId,
            castNameId: castName.castNameId,
          })
        );
      }
      await dispatch(
        fetchNotelClassByCastNameId({
          companyId,
          castNameId: castName?.castNameId || 0,
        })
      );
      await dispatch(fetchCosplay({ companyId, shopId: Number(shopId) }));
      await dispatch(fetchArea({ companyId, shopId: Number(shopId) }));
      await dispatch(fetchHotel({ companyId }));
      const settingBookEmailRes = await SettingBookEmailApi.find(
        companyId,
        +shopId
      );
      setSettingBookEmail(settingBookEmailRes);
      const driverSettingBookEmailRes = await DriverSettingBookEmailApi.find(
        companyId,
        +shopId
      );
      setDriverSettingBookEmail(driverSettingBookEmailRes);
      setIsLoading(false);
    };
    apiCall();
  }, [shopId, guestId, cast]);

  useEffect(() => {
    setFilterCourses(
      courses.filter(
        (course) =>
          (formData?.notelClassId
            ? course.notelClassId === formData?.notelClassId
            : true) &&
          (formData?.courseTime
            ? course.time === formData?.courseTime
            : true) &&
          (formData?.nominationId
            ? course.nominationId === formData?.nominationId
            : true)
      )
    );
  }, [
    formData.courseTime,
    formData.nominationId,
    formData.notelClassId,
    courses,
  ]);

  useEffect(() => {
    setFilterAdditionalTimes(
      additionalTimes.filter((additionalTime) =>
        formData?.notelClassId
          ? !!additionalTime.notelClasses?.find(
              (nc) => nc.notelClassId === formData.notelClassId
            )
          : true
      )
    );
  }, [additionalTimes, formData.notelClassId]);

  useEffect(() => {
    if (formData.areaId) {
      setFilterHotels(
        hotels.filter((hotel) =>
          hotel.areas?.map((area) => area.areaId).includes(formData.areaId)
        )
      );
      setSelectArea(areas.find((area) => area.areaId === formData?.areaId));
    } else {
      setFilterHotels(hotels);
    }
    const hotel = hotels.find((hotel) => hotel.hotelId === formData.hotelId);
    if (hotel) {
      setSelectHotel(hotel);
    }
    if (!hotel || !hotel?.areas) {
      setFilterAreas(
        areas.filter((area) =>
          formData?.shopId
            ? area.shops.some((shop) => shop.shopId === formData.shopId)
            : true
        )
      );
    } else if (hotel?.areas.length === 1) {
      onChange("areaId", hotel?.areas[0].areaId);
      setSelectArea(hotel?.areas[0]);
      setFilterAreas(hotel.areas);
    } else if (hotel.areas.length > 1) {
      setFilterAreas(hotel?.areas);
    }
    onChange("orderAddress2", hotel?.address || formData?.orderAddress2);
  }, [formData.hotelId, formData.areaId, formData.shopId, hotels, areas]);

  useEffect(() => {
    if (!formData?.courseTime) return;
    const totalTime =
      formData?.courseTime +
        formData?.additionalTimeOrders?.reduce(
          (sum: number, additionalTimeOrder: any) =>
            sum +
            additionalTimeOrder.count *
              (additionalTimes?.find(
                (additionalTime) =>
                  additionalTime.additionalTimeId ===
                  additionalTimeOrder.additionalTimeId
              )?.time || 0),
          0
        ) || 0;
    setFormData((prev: any) => ({
      ...prev,
      totalTime,
      planOutTime: DateTime.fromJSDate(formData?.planInTime)
        .plus({ minutes: totalTime })
        .toJSDate(),
    }));
  }, [
    time,
    additionalTimes,
    formData?.courseTime,
    formData?.additionalTimeOrders,
  ]);

  useEffect(() => {
    setBookEmailBody(`
    【受付内容】
    ${
      settingBookEmail?.isOrderDateEnabled
        ? `受付時間: ${
            formData?.createdAt
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.createdAt,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : DateTimeUtils.toFormatAsLocalTimezone(
                  new Date(),
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderCreatedByEnabled
        ? `登録者: ${formData?.createdBy?.name || ""}`
        : ""
    }
    ${
      settingBookEmail?.isOrderUpdatedByEnabled
        ? `更新者: ${formData?.updatedBy?.name || ""}`
        : ""
    }
    
   【顧客情報】
    ${
      settingBookEmail?.isGuestCategoryEnabled
        ? `顧客区分: ${guest?.guestCategory?.name}`
        : ""
    }
    ${
      settingBookEmail?.isGuestNameEnabled
        ? `顧客名: ${guest?.name || ""}様`
        : ""
    }
    ${
      settingBookEmail?.isGuestTelEnabled
        ? `顧客電話番号: ${guest?.tel || ""}`
        : ""
    }
    ${settingBookEmail?.isGuestMemoEnabled ? `メモ: ${guest?.memo || ""}` : ""}

    【予約情報】
    ${
      settingBookEmail?.isShopNameEnabled
        ? `店舗名：${
            shops.find((shop) => shop.shopId === formData?.shopId)?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isCastNameEnabled
        ? `キャスト名：${castName?.name || ""}さん`
        : ""
    }
    ${
      settingBookEmail?.isCastClassNameEnabled
        ? `クラス：${notelClass?.name || ""}`
        : ""
    }
    ${
      settingBookEmail?.isCastDesignateNameEnabled
        ? `指名：${
            nominations.find(
              (nomination) => nomination.nominationId === formData?.nominationId
            )?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isCourseNameEnabled
        ? `コース：${
            courses.find((course) => course.courseId === formData?.courseId)
              ?.name || ""
          }`
        : ""
    }    
    ${
      settingBookEmail?.isAdditionalTimeEnabled
        ? `延長：${
            formData?.additionalTimeOrders
              ?.map((additionalTimeOrder: any) => {
                return `${
                  additionalTimes?.find(
                    (additionalTime) =>
                      additionalTime.additionalTimeId ===
                      additionalTimeOrder.additionalTimeId
                  )?.time || 0
                }分 × ${additionalTimeOrder.count || 0}回`;
              })
              .join(",") || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isTotalTimeEnabled
        ? `合計時間：${formData?.totalTime || 0}分`
        : ""
    }
    ${settingBookEmail?.isDiscountNameEnabled ? `イベント・割引： ` : ""}
    ${
      settingBookEmail?.isOptionNameEnabled
        ? `オプション：${options
            .filter((option) => formData?.options?.includes(option.optionId))
            .map((option) => option.name)
            .join(",")}`
        : ""
    }
    ${
      settingBookEmail?.isCosplayNameEnabled
        ? `コスプレ：${
            cosplays.find((cos) => cos.cosplayId === formData?.cosplayId)
              ?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isAreaEnabled
        ? `エリア：${
            areas.find((area) => area.areaId === formData?.areaId)?.name || ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isHotelEnabled
        ? `ホテル：${
            hotels.find((hotel) => hotel.hotelId === formData?.hotelId)?.name ||
            ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderAddressEnabled
        ? `住所：${formData?.orderAddress || ""}
    住所2：${formData?.orderAddress2 || ""}
        `
        : ""
    }
    ${
      settingBookEmail?.isCarInfoEnabled
        ? `送り：${formData?.outwardDriver?.carType || ""} ${
            formData?.outwardDriver?.carColor || ""
          } ${formData?.outwardDriver?.carNumber || ""}
    迎え：${formData?.returnDriver?.carType || ""} ${
            formData?.returnDriver?.carColor || ""
          } ${formData?.returnDriver?.carNumber || ""}
    `
        : ""
    }
    ${
      settingBookEmail?.isOrderDepartureTimeEnabled
        ? `出発：${
            formData?.departureTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.departureTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderPlanInTimeEnabled
        ? `予定IN：${
            formData?.planInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.planInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderActualInTimeEnabled
        ? `実IN：${
            formData?.actualInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderActualEndTimeEnabled
        ? `実OUT：${
            formData?.actualEndTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualEndTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      settingBookEmail?.isOrderMemoEnabled
        ? `メモ：${formData?.memo || ""}`
        : ""
    }
    ${
      settingBookEmail?.isOrderPaymentTypeEnabled
        ? `支払い方法：${
            EnumUtils.mapToEnum(PaymentType, formData?.paymentType) || ""
          }`
        : ""
    }
    ${settingBookEmail ? `総額: ${formData?.totalFee || 0}円` : ""}
    `);
  }, [
    settingBookEmail,
    formData,
    castName,
    guest,
    cosplays,
    areas,
    hotels,
    options,
    courses,
    nominations,
    additionalTimes,
  ]);

  useEffect(() => {
    setDriverBookEmailBody(`
    【受付内容】
    ${
      driverSettingBookEmail?.isOrderDateEnabled
        ? `受付時間: ${
            formData?.createdAt
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.createdAt,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : DateTimeUtils.toFormatAsLocalTimezone(
                  new Date(),
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderCreatedByEnabled
        ? `登録者: ${formData?.createdBy?.name || ""}`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderUpdatedByEnabled
        ? `更新者: ${formData?.updatedBy?.name || ""}`
        : ""
    }
    
   【顧客情報】
    ${
      driverSettingBookEmail?.isGuestCategoryEnabled
        ? `顧客区分: ${guest?.guestCategory?.name || ""}`
        : ""
    }
    ${
      driverSettingBookEmail?.isGuestNameEnabled
        ? `顧客名: ${guest?.name || ""}様`
        : ""
    }
    ${
      driverSettingBookEmail?.isGuestTelEnabled
        ? `顧客電話番号: ${guest?.tel || ""}`
        : ""
    }
    ${
      driverSettingBookEmail?.isGuestMemoEnabled
        ? `メモ: ${guest?.memo || ""}`
        : ""
    }

    【予約情報】
    ${
      driverSettingBookEmail?.isShopNameEnabled
        ? `店舗名：${
            shops.find((shop) => shop.shopId === formData?.shopId)?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastNameEnabled
        ? `キャスト名：${castName?.name || ""}さん`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastDesignateCountEnabled
        ? `指名回数：${formData?.additionalTimeCount || ""}回`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastClassNameEnabled
        ? `クラス：${notelClass?.name || ""}`
        : ""
    }
    ${
      driverSettingBookEmail?.isCastDesignateNameEnabled
        ? `指名：${
            nominations.find(
              (nomination) => nomination.nominationId === formData?.nominationId
            )?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isCourseNameEnabled
        ? `コース：${
            courses.find((course) => course.courseId === formData?.courseId)
              ?.name || ""
          }`
        : ""
    }    
    ${
      driverSettingBookEmail?.isAdditionalTimeEnabled
        ? `延長：${
            formData?.additionalTimeOrders
              ?.map((additionalTimeOrder: any) => {
                return `${
                  additionalTimes?.find(
                    (additionalTime) =>
                      additionalTime.additionalTimeId ===
                      additionalTimeOrder.additionalTimeId
                  )?.time || 0
                }分 × ${additionalTimeOrder.count || 0}回`;
              })
              .join(",") || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isTotalTimeEnabled
        ? `合計時間：${formData?.totalTime || 0}分`
        : ""
    }
    ${driverSettingBookEmail?.isDiscountNameEnabled ? `イベント・割引： ` : ""}
    ${
      driverSettingBookEmail?.isOptionNameEnabled
        ? `オプション：${options
            .filter((option) => formData?.options?.includes(option.optionId))
            .map((option) => option.name)
            .join(",")}`
        : ""
    }
    ${
      driverSettingBookEmail?.isCosplayNameEnabled
        ? `コスプレ：${
            cosplays.find((cos) => cos.cosplayId === formData?.cosplayId)
              ?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isAreaEnabled
        ? `エリア：${
            areas.find((area) => area.areaId === formData?.areaId)?.name || ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isHotelEnabled
        ? `ホテル：${
            hotels.find((hotel) => hotel.hotelId === formData?.hotelId)?.name ||
            ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderAddressEnabled
        ? `住所：${formData?.orderAddress || ""}
    住所2：${formData?.orderAddress2 || ""}
        `
        : ""
    }
    ${
      driverSettingBookEmail?.isCarInfoEnabled
        ? `送り：${formData?.outwardDriver?.carType || ""} ${
            formData?.outwardDriver?.carColor || ""
          } ${formData?.outwardDriver?.carNumber || ""}
    迎え：${formData?.returnDriver?.carType || ""} ${
            formData?.returnDriver?.carColor || ""
          } ${formData?.returnDriver?.carNumber || ""}
    `
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderDepartureTimeEnabled
        ? `出発：${
            formData?.departureTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.departureTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderPlanInTimeEnabled
        ? `予定IN：${
            formData?.planInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.planInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderActualInTimeEnabled
        ? `実IN：${
            formData?.actualInTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualInTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderActualEndTimeEnabled
        ? `実OUT：${
            formData?.actualEndTime
              ? DateTimeUtils.toFormatAsLocalTimezone(
                  formData?.actualEndTime,
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""
          }`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderMemoEnabled
        ? `メモ：${formData?.memo || ""}`
        : ""
    }
    ${
      driverSettingBookEmail?.isOrderPaymentTypeEnabled
        ? `支払い方法：${
            EnumUtils.mapToEnum(PaymentType, formData?.paymentType) || ""
          }`
        : ""
    }
    ${driverSettingBookEmail ? `総額: ${formData?.totalFee || 0}円` : ""}
    `);
  }, [
    driverSettingBookEmail,
    formData,
    castName,
    guest,
    cosplays,
    areas,
    hotels,
    options,
    courses,
    nominations,
    additionalTimes,
  ]);

  useEffect(() => {
    onChange("bookEmailBody", bookEmailBody);
  }, [bookEmailBody]);

  useEffect(() => {
    onChange("driverBookEmailBody", driverBookEmailBody);
  }, [driverBookEmailBody]);

  const onChange = (key: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [key]: value }));
  };
  const addAdditionalTimeOrder = () => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: [
        ...(prev.additionalTimeOrders || []),
        {
          additionalTimeId: null,
          count: 0,
        },
      ],
    }));
  };
  const onChangeAdditionalTimeOrder = (
    index: number,
    key: string,
    value: any
  ) => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: prev.additionalTimeOrders?.map(
        (order: any, i: number) => {
          if (i === index) {
            return {
              ...order,
              [key]: Number(value),
            };
          }
          return order;
        }
      ),
    }));
  };
  const deleteAdditionalTimeOrder = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      additionalTimeOrders: prev.additionalTimeOrders.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };
  const onClickSave = () => {
    const createReq = new CreateOrderReq({
      ...formData,
    });
    dispatch(createOrder({ companyId, req: createReq }));
    history.push("/");
  };

  if (isConfirm) {
    return (
      <Box display={"flex"} flexDirection={"column"} margin={2}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          予約詳細テキストコピー
        </Typography>
        <TextField
          multiline
          variant="outlined"
          style={{ margin: 5 }}
          rows={20}
          rowsMax={1000}
          value={bookEmailBody}
        />
        <Box display={"flex"} marginLeft={2} marginTop={1}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => setIsConfirm(false)}
          >
            再編集
          </Button>
        </Box>
        <Box display={"flex"} margin={2}>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => {
              onClickSave();
            }}
            fullWidth
          >
            申請
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box display={"flex"} flexDirection={"column"} margin={2}>
      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
        {shops.find((shop) => shop.shopId === Number(shopId))?.name || ""}
      </Typography>

      <Box display={"flex"} justifyContent={"space-between"} marginY={1}>
        <Typography>受注情報</Typography>
        <Typography>顧客名：{guest?.name || ""}</Typography>
      </Box>
      <Box display={"flex"} marginY={1}>
        <TextField
          type={"date"}
          value={date}
          disabled={true}
          label={"日付"}
          fullWidth
        />
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel>時間</InputLabel>
            <Select
              value={formData?.courseTime || ""}
              onChange={(event) => onChange("courseTime", event.target.value)}
              fullWidth
            >
              <option aria-label="None" value="" />
              {filterCourses
                .filter(
                  (element, index, self) =>
                    self.findIndex((e) => e.time === element.time) === index
                )
                .sort((a, b) => (a.time > b.time ? 1 : -1))
                .map((course) => (
                  <MenuItem value={course.time} key={course.time}>
                    {course.time}分
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel htmlFor={"nomination"}>指名</InputLabel>
            <Select
              fullWidth
              inputProps={{
                name: "nomination",
                id: "nomination",
              }}
              value={formData?.nominationId || ""}
              onChange={(event) => onChange("nominationId", event.target.value)}
            >
              <option aria-label="None" value="" />
              {nominations.map((nomination) => (
                <MenuItem
                  value={nomination.nominationId}
                  key={nomination.nominationId}
                >
                  {nomination.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel htmlFor={"course"}>コース</InputLabel>
            <Select
              fullWidth
              inputProps={{
                name: "course",
                id: "course",
              }}
              value={formData?.courseId || ""}
              onChange={(event) => {
                onChange("courseId", event.target.value);
                if (!event.target.value && formData?.courseTime) {
                  onChange("courseTime", "");
                }
              }}
            >
              <option aria-label="None" value="" />
              {filterCourses.map((course) => (
                <MenuItem value={course.courseId} key={course.courseId}>
                  {course.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel htmlFor={"additionalTime"}>延長</InputLabel>
            <Box display="flex" alignItems="center" flexDirection={"column"}>
              {formData?.additionalTimeOrders?.map(
                (selectAdditionalTimeOrder: any, i: number) => (
                  <Box
                    display={"flex"}
                    key={i}
                    alignItems={"center"}
                    marginTop={i > 0 ? 1 : 0}
                  >
                    <Select
                      inputProps={{
                        name: "additionalTime",
                        id: "additionalTime",
                      }}
                      style={{
                        width: 200,
                      }}
                      value={selectAdditionalTimeOrder.additionalTimeId || ""}
                      onChange={(event) =>
                        onChangeAdditionalTimeOrder(
                          i,
                          "additionalTimeId",
                          event.target.value
                        )
                      }
                    >
                      <option aria-label="None" value="" />
                      {filterAdditionalTimes.map((additionalTime) => (
                        <MenuItem
                          value={additionalTime.additionalTimeId}
                          key={additionalTime.additionalTimeId}
                        >
                          {additionalTime.time}分
                          {additionalTime.name && `(${additionalTime.name})`}
                        </MenuItem>
                      ))}
                    </Select>
                    <p style={{ margin: "0 5px" }}>×</p>
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ width: "50px" }}
                      value={selectAdditionalTimeOrder.count || 0}
                      onChange={(event) =>
                        onChangeAdditionalTimeOrder(
                          i,
                          "count",
                          event.target.value
                        )
                      }
                    />
                    <p style={{ margin: "0 0 0 5px" }}>回</p>
                    {i === 0 ? (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        onClick={() => {
                          addAdditionalTimeOrder();
                        }}
                      >
                        <Add />
                      </Box>
                    ) : (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        onClick={() => {
                          deleteAdditionalTimeOrder(i);
                        }}
                      >
                        <Delete />
                      </Box>
                    )}
                  </Box>
                )
              )}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel htmlFor={"option"}>オプション</InputLabel>
            <Select
              inputProps={{
                name: "option",
                id: "option",
              }}
              fullWidth
              value={formData?.options || []}
              multiple
              renderValue={(selected) =>
                options
                  .filter((n) => (selected as number[]).includes(n.optionId))
                  .map((n) => n.name)
                  .join(", ")
              }
              onChange={(
                event: ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                onChange("options", event.target.value);
                const option = options.filter((option) =>
                  (event.target.value as number[]).includes(option.optionId)
                );
                onChange(
                  "optionFee",
                  option?.reduce((o, b) => b.totalFee + o, 0) || 0
                );
                onChange(
                  "optionShopFee",
                  option?.reduce((o, b) => b.shopFee + o, 0) || 0
                );
                onChange(
                  "optionCastFee",
                  option?.reduce((o, b) => b.castFee + o, 0) || 0
                );
              }}
            >
              {options.map((option) => (
                <MenuItem value={option.optionId} key={option.optionId}>
                  <Checkbox
                    checked={formData?.options?.indexOf(option.optionId) > -1}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel htmlFor={"cosplay"}>コスプレ</InputLabel>
            <Select
              inputProps={{
                name: "cosplay",
                id: "cosplay",
              }}
              fullWidth
              value={formData?.cosplayId || ""}
              onChange={(
                event: ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                onChange("cosplayId", event.target.value);
                const cosplay = cosplays.find(
                  (cosplay) =>
                    cosplay.cosplayId === (event.target.value as number)
                );
                onChange("cosplayFee", cosplay?.totalFee || 0);
                onChange("cosplayShopFee", cosplay?.shopFee || 0);
                onChange("cosplayCastFee", cosplay?.castFee || 0);
              }}
            >
              <option aria-label="None" value="" />
              {cosplays.map((cosplay) => (
                <MenuItem value={cosplay.cosplayId} key={cosplay.cosplayId}>
                  {cosplay.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel htmlFor={"area"}>エリア</InputLabel>
            <Autocomplete
              fullWidth
              options={filterAreas}
              value={selectArea || null}
              getOptionLabel={(option: any) => option.name || ""}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, value) => {
                setSelectArea(value || undefined);
                onChange("areaId", value?.areaId);
                onChange("areaFee", value?.totalFee || 0);
                onChange("areaShopFee", value?.shopFee || 0);
                onChange("areaCastFee", value?.castFee || 0);
              }}
              noOptionsText="選択肢がありません"
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <InputLabel htmlFor={"hotel"}>ホテル</InputLabel>
            <Autocomplete
              fullWidth
              options={filterHotels}
              value={selectHotel || null}
              getOptionLabel={(option: any) => option.name || ""}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, value) => {
                setSelectHotel(value || undefined);
                onChange("hotelId", value?.hotelId);
              }}
              noOptionsText="選択肢がありません"
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <TextField
              label="住所"
              size="small"
              variant="outlined"
              fullWidth
              value={formData?.orderAddress || ""}
              onChange={(event) => {
                event.persist();
                onChange("orderAddress", event.target.value);
              }}
            />
          </Box>
          <Box display={"flex"} flexDirection={"column"} marginY={1}>
            <TextField
              label="住所2"
              size="small"
              variant="outlined"
              fullWidth
              value={formData?.orderAddress2 || ""}
              onChange={(event) => {
                event.persist();
                onChange("orderAddress2", event.target.value);
              }}
            />
          </Box>
          <Box display={"flex"} justifyContent={"space-evenly"} marginY={1}>
            <Typography>出発 {time}</Typography>
            <Typography>予定IN {time}</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-evenly"} marginY={1}>
            <Box display={"flex"} flexDirection={"column"}>
              <InputLabel htmlFor={"paymentType"}>支払い</InputLabel>
              <Select
                inputProps={{
                  name: "paymentType",
                  id: "paymentType",
                }}
                style={{ width: 100 }}
                onChange={(event) =>
                  onChange("paymentType", event.target.value)
                }
                value={formData["paymentType"] || "cash"}
              >
                <option aria-label="None" value="" />
                {Object.entries(PaymentType).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <TextField
              label="予定OUT"
              size="small"
              variant="outlined"
              disabled={true}
              value={
                formData?.planOutTime
                  ? DateTime.fromJSDate(formData?.planOutTime).toFormat(
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )
                  : ""
              }
            />
          </Box>
          <Box display={"flex"} marginY={1}>
            <TextField
              multiline
              label="メモ"
              variant="outlined"
              fullWidth
              rows={3}
              rowsMax={1000}
              value={formData?.memo}
              onChange={(event) => {
                event.persist();
                onChange("memo", event.target.value);
              }}
            />
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"} marginY={1}>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={() => {
                if (
                  !formData?.courseId ||
                  !formData?.courseTime ||
                  !formData?.nominationId ||
                  !formData?.areaId
                ) {
                  return alert("時間、コース、指名、エリアは必須項目です");
                }
                setIsConfirm(true);
              }}
            >
              予約確認
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
export default NewOrder;
