import { createSlice } from "@reduxjs/toolkit";
import GuestRes from "types/res/guest/GuestRes";
import { fetchOneGuest } from "redux/actions/guest";

export type GuestState = GuestRes;

const initialState = {} as GuestState;

const guestsSlice = createSlice({
  name: "guests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOneGuest.fulfilled, (_, { payload }) => payload);
  },
});

export default guestsSlice.reducer;
