import {
  fetchGuestNotes,
  fetchOneGuestNote,
  putGuestNote,
} from "redux/actions/guestnotes";
import { createSlice } from "@reduxjs/toolkit";
import GuestNoteRes from "types/res/guestnote/GuestNoteRes";

export type GuestNoteState = GuestNoteRes[];

const initialState = {} as GuestNoteState;

const guestNotesSlice = createSlice({
  name: "guestNotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGuestNotes.fulfilled, (_, { payload }) => payload);
    builder.addCase(fetchOneGuestNote.fulfilled, (_, { payload }) => [payload]);
    builder.addCase(putGuestNote.fulfilled, (state, { payload }) =>
      state.map((guestNote) =>
        guestNote.guestNoteId === payload.guestNoteId ? payload : guestNote
      )
    );
  },
});

export default guestNotesSlice.reducer;
