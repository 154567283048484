import RequestUtils from "../utils/RequestUtils";
import CourseRes from "../types/res/course/CourseRes";

export default class CourseApi {
  static async fetchCourse(companyId: number, shopId: number) {
    return await RequestUtils.getArray(
      CourseRes,
      `/course/findAll/${companyId}/${shopId}`
    );
  }
}
