import { Box, Divider, List, ListItem, Typography } from "@material-ui/core";
import DateTimeUtils, { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import React from "react";
import OrderRes from "../../types/res/order/OrderRes";

type Props = {
  payoff: OrderRes[];
};
const PayoffItem: React.FC<Props> = ({ payoff }) => {
  if (!payoff.length)
    return (
      <Box display="flex" padding={1} justifyContent="center">
        売上がありません
      </Box>
    );
  return (
    <List>
      {payoff.map((payoff) => (
        <>
          <ListItem style={{ backgroundColor: "#eaeaea" }}>
            <Box
              display="flex"
              flex="1"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" flexBasis="80%">
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    受付日
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {DateTimeUtils.toFormatAsLocalTimezone(
                      payoff.createdAt,
                      FORMAT_TYPE.YEAR_DATE_TIME
                    )}
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    顧客名
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.guest?.name}
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    店名
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.shop?.name}
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    コース
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.course?.name}
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    住所
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.orderAddress}
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    出発
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.departureTime
                      ? DateTimeUtils.toFormatAsLocalTimezone(
                          payoff.departureTime,
                          FORMAT_TYPE.YEAR_DATE_TIME
                        )
                      : ""}
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    インボイス/厚生費
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.invoiceFee || 0}円
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    売上
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.totalFee || 0}円
                  </Typography>
                </Box>
                <Box display="flex" marginBottom={0.5}>
                  <Typography style={{ width: "80px", fontWeight: "bold" }}>
                    支給
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {payoff.totalCastFee || 0}円
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
};
export default PayoffItem;
