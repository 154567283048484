import { Expose, Type } from "class-transformer";
import CastRes from "../cast/CastRes";
import ShopRes from "../shop/ShopRes";
import ShiftStatus from "types/enum/ShiftStatus";
import CompanyRes from "types/res/company/CompanyRes";

export default class CastShiftRes {
  @Expose() readonly castShiftId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly shopId!: number;
  @Expose() readonly castId!: number;
  @Type(() => Date)
  @Expose()
  readonly planWorkStart!: Date;
  @Type(() => Date)
  @Expose()
  readonly planWorkEnd!: Date;
  @Type(() => Date)
  @Expose()
  readonly actualWorkStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly actualWorkEnd!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffStart!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly workOffEnd!: Date | null;
  @Expose() readonly status!: ShiftStatus;
  @Expose() readonly staffMemo!: string | null;
  @Expose() readonly castMemo!: string | null;
  @Type(() => CompanyRes)
  @Expose()
  readonly company!: CompanyRes;
  @Type(() => CastRes)
  @Expose()
  readonly cast!: CastRes;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
