import { createAsyncThunk } from "@reduxjs/toolkit";
import ShiftApi from "api/ShiftApi";
import CreateCastShiftReq from "../../types/req/shift/CreateCastShiftReq";
import UpdateCastShiftReq from "../../types/req/shift/UpdateCastShiftReq";
import PostCastShiftMessageReq from "types/req/shift/PostCastShiftMessageReq";
import DeleteCastShiftReq from "types/req/shift/DeleteCastShiftReq";

export const fetchShifts = createAsyncThunk(
  "fetchShifts",
  ({
    companyId,
    castId,
    month,
  }: {
    companyId: number;
    castId: number;
    month: string;
  }) => ShiftApi.fetchShifts(companyId, castId, month)
);

export const fetchShiftsByDate = createAsyncThunk(
  "fetchShiftsByDate",
  ({
    companyId,
    castId,
    startDate,
    endDate,
  }: {
    companyId: number;
    castId: number;
    startDate: string;
    endDate: string;
  }) => ShiftApi.fetchShiftsByDate(companyId, castId, startDate, endDate)
);

export const postShift = createAsyncThunk(
  "postShift",
  ({ companyId, req }: { companyId: number; req: CreateCastShiftReq }) =>
    ShiftApi.postShift(companyId, req)
);

export const putShift = createAsyncThunk(
  "putShift",
  ({ companyId, req }: { companyId: number; req: UpdateCastShiftReq }) =>
    ShiftApi.putShift(companyId, req)
);

export const deleteShift = createAsyncThunk(
  "deleteShift",
  async ({
    companyId,
    req,
  }: {
    companyId: number;
    req: DeleteCastShiftReq;
  }) => {
    await ShiftApi.deleteShift(companyId, req);
    return req;
  }
);

export const postShiftMessage = createAsyncThunk(
  "postShiftMessage",
  ({ companyId, req }: { companyId: number; req: PostCastShiftMessageReq }) =>
    ShiftApi.postShiftMessage(companyId, req)
);

export const approveShift = createAsyncThunk(
  "postApproveShift",
  ({ companyId, castShiftId }: { companyId: number; castShiftId: number }) =>
    ShiftApi.postApproveShift(companyId, castShiftId)
);

export const updateRestTimeShift = createAsyncThunk(
  "postUpdateRestTimeShift",
  ({
    companyId,
    castShiftId,
    startTime,
    endTime,
  }: {
    companyId: number;
    castShiftId: number;
    startTime: string;
    endTime: string;
  }) =>
    ShiftApi.postUpdateRestTimeShift(companyId, castShiftId, startTime, endTime)
);
