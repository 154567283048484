import { Expose, Type } from "class-transformer";
import StaffRole from "types/enum/StaffRole";
import ShopRes from "../shop/ShopRes";

export default class StaffRes {
  @Expose() readonly companyId!: number;
  @Expose() readonly staffId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly name!: string;
  @Expose() readonly role!: StaffRole;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
