import OrderRes from "types/res/order/OrderRes";
import RequestUtils from "../utils/RequestUtils";
import CreateOrderReq from "types/req/order/order/CreateOrderReq";
import OrderStatus from "types/enum/OrderStatus";

export default class OrderApi {
  static async fetchOrders(
    companyId: number,
    castId: number,
    startDate: string,
    endDate: string,
    orderId?: number
  ) {
    return await RequestUtils.getArray(
      OrderRes,
      `/order/findAll/${companyId}/${castId}/${startDate}/${endDate}${
        orderId ? `?orderId=${orderId}` : ""
      }`
    );
  }

  static async create(companyId: number, req: CreateOrderReq) {
    return await RequestUtils.post(OrderRes, `/order/create/${companyId}`, req);
  }

  static async putIn(companyId: number, orderId: number) {
    return await RequestUtils.post(
      OrderRes,
      `/order/putIn/${companyId}/${orderId}`,
      {}
    );
  }
  static async putOut(companyId: number, orderId: number) {
    return await RequestUtils.post(
      OrderRes,
      `/order/putOut/${companyId}/${orderId}`,
      {}
    );
  }
  static async addOptions(
    companyId: number,
    orderId: number,
    options: number[]
  ) {
    return await RequestUtils.post(
      OrderRes,
      `/order/addOptions/${companyId}/${orderId}`,
      { options }
    );
  }

  static async addAddress(companyId: number, orderId: number, address: string) {
    return await RequestUtils.post(
      OrderRes,
      `/order/addAddress/${companyId}/${orderId}`,
      { address }
    );
  }

  static async castConfirm(companyId: number, orderId: number) {
    return await RequestUtils.post(
      OrderRes,
      `/order/castConfirm/${companyId}/${orderId}`,
      {}
    );
  }

  static async updateStatus(
    companyId: number,
    orderId: number,
    status: OrderStatus
  ) {
    return await RequestUtils.post(
      OrderRes,
      `/order/updateStatus/${companyId}/${orderId}`,
      { status }
    );
  }
}
