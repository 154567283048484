import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { Backdrop as MuiBackdrop } from "@material-ui/core";

const Backdrop = styled(MuiBackdrop)`
  z-index: 20;
  color: white;
`;

const BackdropSpinner = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Backdrop open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropSpinner;
