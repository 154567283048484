import { createAsyncThunk } from "@reduxjs/toolkit";
import OrderApi from "api/OrderApi";
import CreateOrderReq from "types/req/order/order/CreateOrderReq";
import OrderStatus from "types/enum/OrderStatus";

export const fetchOrders = createAsyncThunk(
  "fetchOrders",
  ({
    companyId,
    castId,
    startDate,
    endDate,
    orderId,
  }: {
    companyId: number;
    castId: number;
    startDate: string;
    endDate: string;
    orderId?: number;
  }) => OrderApi.fetchOrders(companyId, castId, startDate, endDate, orderId)
);

export const createOrder = createAsyncThunk(
  "crateOrder",
  ({ companyId, req }: { companyId: number; req: CreateOrderReq }) =>
    OrderApi.create(companyId, req)
);

export const putScheduleIn = createAsyncThunk(
  "putScheduleIn",
  ({ companyId, orderId }: { companyId: number; orderId: number }) =>
    OrderApi.putIn(companyId, orderId)
);

export const putScheduleOut = createAsyncThunk(
  "putScheduleOut",
  ({ companyId, orderId }: { companyId: number; orderId: number }) =>
    OrderApi.putOut(companyId, orderId)
);

export const addOptions = createAsyncThunk(
  "addOptions",
  ({
    companyId,
    orderId,
    options,
  }: {
    companyId: number;
    orderId: number;
    options: number[];
  }) => OrderApi.addOptions(companyId, orderId, options)
);

export const addAddress = createAsyncThunk(
  "addAddress",
  ({
    companyId,
    orderId,
    address,
  }: {
    companyId: number;
    orderId: number;
    address: string;
  }) => OrderApi.addAddress(companyId, orderId, address)
);

export const updateStatus = createAsyncThunk(
  "updateStatus",
  ({
    companyId,
    orderId,
    status,
  }: {
    companyId: number;
    orderId: number;
    status: OrderStatus;
  }) => OrderApi.updateStatus(companyId, orderId, status)
);

export const castConfirm = createAsyncThunk(
  "castConfirm",
  ({ companyId, orderId }: { companyId: number; orderId: number }) =>
    OrderApi.castConfirm(companyId, orderId)
);
