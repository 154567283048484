import React, { useState, useEffect } from "react";
import DateTimeUtils from "utils/DateTimeUtils";
import { useSelector, useDispatch } from "react-redux";
import { configureKey } from "redux/reducers/shifts";
import useAsyncCallback from "hooks/useAsyncCallback";
import {
  fetchShifts,
  postShiftMessage,
  approveShift,
} from "redux/actions/shifts";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import BackdropSpinner from "components/BackdropSpinner";
import toastActions, { ToastType } from "redux/actions/toast";
import {
  ShiftContent,
  DateChanger,
  Container,
  DateTimeContainer,
} from "./components";
import { fetchCompany } from "redux/actions/company";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import CheckIcon from "@material-ui/icons/Check";
const ShiftConfirm = () => {
  const dispatch = useDispatch();
  const [shiftApproveModal, setShiftApproveShow] = useModal("shiftApprove");
  const cast = useSelector((state) => state.account.cast);
  const company = useSelector((state) => state.company);
  const [currentDate, setCurrentDate] = useState(
    DateTimeUtils.local().startOf("days")
  );
  const [month, setMonth] = useState(currentDate.toFormat("yyyy-MM"));
  const [message, setMessage] = useState("");

  const shift = useSelector(
    (state) => state.shifts[configureKey(currentDate.toJSDate(), company)]
  );
  const companyTel = company.tel;

  useEffect(() => {
    dispatch(fetchCompany({ companyId: cast.companyId }));
  }, [cast.companyId]);

  const {
    refresh: refreshShifts,
    isLoading: isFetchingShifts,
  } = useAsyncCallback(
    () =>
      dispatch(
        fetchShifts({ companyId: cast.companyId, castId: cast.castId, month })
      ),
    [month, cast]
  );

  const {
    refresh: postMessage,
    isLoading: isPostingMessage,
  } = useAsyncCallback(
    () =>
      dispatch(
        postShiftMessage({
          companyId: cast.companyId,
          req: {
            castShiftId: shift.castShiftId,
            castMemo: message,
          },
        })
      ),
    [message, shift, cast]
  );

  const {
    refresh: postApproveShift,
    isLoading: isApprovingShift,
  } = useAsyncCallback(
    () =>
      dispatch(
        approveShift({
          companyId: cast.companyId,
          castShiftId: shift.castShiftId,
        })
      ),
    [message, shift, cast]
  );

  const onApproveShift = async () => {
    const res = await postApproveShift();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  const onOpenApproveModal = () => {
    setMessage((prev) =>
      prev?.includes("退勤時刻までに上がる") ||
      prev.includes("退勤時刻まで仕事を受け付ける")
        ? prev
        : prev + "\n退勤時刻までに上がる"
    );
    setShiftApproveShow(true);
  };

  const onChangeMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const onClickSendMessage = async () => {
    const res = await postMessage();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  const onClickNextDay = () => {
    setCurrentDate((prev) => prev.plus({ day: 1 }));
  };

  const onClickPreviousDay = () => {
    setCurrentDate((prev) => prev.minus({ day: 1 }));
  };

  useEffect(() => {
    refreshShifts();
  }, [refreshShifts]);

  useEffect(() => {
    setMonth(currentDate.toFormat("yyyy-MM"));
  }, [currentDate]);

  useEffect(() => {
    setMessage(shift?.castMemo || "");
  }, [shift]);

  return (
    <>
      <Container>
        <DateTimeContainer>
          {currentDate.toFormat("yyyy'/'MM'/'dd (EEEE)")}
        </DateTimeContainer>
        <Divider flexItem style={{ height: 1 }} variant="middle" />
        <ShiftContent
          month={month}
          currentDate={currentDate}
          shift={shift}
          message={message}
          onApproveShift={onOpenApproveModal}
          companyTel={companyTel}
          onChangeMessage={onChangeMessage}
          onClickSendMessage={onClickSendMessage}
        />
        <DateChanger
          onClickPreviousDay={onClickPreviousDay}
          onClickNextDay={onClickNextDay}
        />
      </Container>
      <Modal
        show={shiftApproveModal?.show || false}
        setShow={setShiftApproveShow}
        title="シフト確認"
      >
        <Box display={"flex"} flexDirection={"column"} margin={2}>
          <Box
            display={"flex"}
            onClick={() =>
              setMessage((prev) => {
                const text = prev.replace("\n退勤時刻まで仕事を受け付ける", "");
                return text.includes("退勤時刻までに上がる")
                  ? text
                  : text + "\n退勤時刻までに上がる";
              })
            }
            style={{
              cursor: "pointer",
            }}
            height={30}
          >
            <Box width={30}>
              {message.includes("退勤時刻までに上がる") && (
                <CheckIcon width={20} color={"error"} />
              )}
            </Box>
            <Typography style={{ fontWeight: "bold" }}>
              退勤時刻までに上がる
            </Typography>
          </Box>
          <Box
            display={"flex"}
            onClick={() =>
              setMessage((prev) => {
                const text = prev.replace("\n退勤時刻までに上がる", "");
                return text.includes("退勤時刻まで仕事を受け付ける")
                  ? text
                  : text + "\n退勤時刻まで仕事を受け付ける";
              })
            }
            marginY={2}
            height={30}
            style={{
              cursor: "pointer",
            }}
          >
            <Box width={30}>
              {message.includes("退勤時刻まで仕事を受け付ける") && (
                <CheckIcon width={20} color={"error"} />
              )}
            </Box>
            <Typography style={{ fontWeight: "bold" }}>
              退勤時刻まで仕事を受け付ける
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              await onApproveShift();
              await onClickSendMessage();
              setShiftApproveShow(false);
            }}
            style={{ marginLeft: 10 }}
          >
            <Typography>送信</Typography>
          </Button>
        </Box>
      </Modal>
      <BackdropSpinner
        isLoading={isFetchingShifts || isApprovingShift || isPostingMessage}
      />
    </>
  );
};

export default ShiftConfirm;
