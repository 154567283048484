import { createSlice } from "@reduxjs/toolkit";
import CompanyRes from "types/res/company/CompanyRes";
import { fetchCompany } from "redux/actions/company";

export type CompanyState = CompanyRes;

const initialState = {} as CompanyState;

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompany.fulfilled, (_, { payload }) => payload);
  },
});

export default companySlice.reducer;
