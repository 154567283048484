import RequestUtils from "../utils/RequestUtils";
import CosplayRes from "../types/res/cosplay/CosplayRes";

export default class CosplayApi {
  static async fetchCosplay(companyId: number, shopId: number) {
    return await RequestUtils.getArray(
      CosplayRes,
      `/cosplay/findAll/${companyId}/${shopId}`
    );
  }
}
