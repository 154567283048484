import { DateTime, Settings } from "luxon";
Settings.defaultLocale = "JST";

export enum FORMAT_TYPE {
  DAY_LIST = "MM/dd",
  YEAR_DAY = "yyyy-MM-dd",
  YEAR_DATE_TIME = "yyyy-MM-dd HH:mm",
  TIME = "HH:mm",
  HOUR_AMPM = "ha",
}
export default class DateTimeUtils extends DateTime {
  // private constructor() {}

  public static toFormatAsLocalTimezone(d: Date, format: string) {
    return DateTime.fromJSDate(d).toFormat(format);
  }

  public static convertDateToSeconds(d: Date) {
    return DateTime.fromJSDate(d).toSeconds();
  }

  public static convertStringToSeconds(s: string, format: string) {
    return DateTime.fromFormat(s, format).toSeconds();
  }

  public static convertSecondsToString(seconds: number, format: string) {
    return DateTime.fromSeconds(seconds).toFormat(format);
  }
}
