import ReactCalendar from "react-calendar";
import styled from "styled-components";
import { CircularProgress, Button } from "@material-ui/core";

export const Calendar = styled(ReactCalendar)`
  width: 100%;
`;

export const FormContainer = styled.div`
  padding: 16px;
`;

export const FormInner = styled.div`
  display: flex;
  align-items: center;
`;

export const TimePickerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  > * {
    margin-right: 8px;
  }
`;

export const Spinner = styled(CircularProgress)`
  color: white;
`;

export const SubmitButton = styled(Button)`
  margin-top: 16px;
  height: 44px;
`;
