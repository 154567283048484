import RequestUtils from "../utils/RequestUtils";
import NotelClassRes from "../types/res/notelClass/NotelClassRes";

export default class NotelClassApi {
  static async fetchNotelClassByCastNameId(
    companyId: number,
    castNameId: number
  ) {
    return await RequestUtils.get(
      NotelClassRes,
      `/notelClass/findByCastNameId/${companyId}/${castNameId}`
    );
  }
}
