import styled from "styled-components";

import { Button as MuiButton } from "@material-ui/core";
import { spacing } from "@material-ui/system";

// TODO: Was not sure how to fix this🤔
export const Button: any = styled(MuiButton)(spacing);

export const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;
