import RequestUtils from "utils/RequestUtils";
import SettingBookEmailRes from "types/res/settingBookEmail/SettingBookEmailRes";

export default class SettingBookEmailApi {
  private constructor() {}

  static async find(
    companyId: number,
    shopId: number
  ): Promise<SettingBookEmailRes> {
    return await RequestUtils.get(
      SettingBookEmailRes,
      `/settingBookEmail/find/${companyId}/${shopId}`
    );
  }
}
