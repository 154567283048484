import React from "react";
import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import toastActions, { ToastType } from "redux/actions/toast";

const colors = {
  success: "#4caf50",
  failure: "#EB5757",
};

const Container = styled.div<{ type: ToastType }>`
  background-color: ${({ type }) =>
    type === ToastType.Success ? colors["success"] : colors["failure"]};
  width: 80%;
  padding: 8px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Toast = () => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast);
  const onClose = () => {
    dispatch(toastActions.hideToast());
  };
  return (
    <Snackbar open={Boolean(toast)} autoHideDuration={3000} onClose={onClose}>
      {toast ? (
        <Container type={toast.type}>{toast.text}</Container>
      ) : undefined}
    </Snackbar>
  );
};
