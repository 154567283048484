import { createSlice } from "@reduxjs/toolkit";
import AdditionalTimeRes from "types/res/additionalTime/AdditionalTimeRes";
import { fetchAdditionalTime } from "redux/actions/additionalTime";

export type AdditionalTimeState = AdditionalTimeRes[];

const initialState = [] as AdditionalTimeState;

const additionalTimeSlice = createSlice({
  name: "additionalTime",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAdditionalTime.fulfilled, (_, { payload }) => payload);
  },
});

export default additionalTimeSlice.reducer;
