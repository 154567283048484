import { AppBar, Box, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayoffs } from "redux/actions/payoff";
import { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import { CalendarToday } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { DateType } from "@date-io/type";
import { DateTime } from "luxon";
import PayoffItem from "./components";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `payoff-tab-${index}`,
    "aria-controls": `payoff-tabpanel-${index}`,
  };
}

const Payoff = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const cast = useSelector((state) => state.account.cast);
  const payoff = useSelector((state) => state.payoff);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const settledPayoff = payoff.filter((payoff) => payoff.payoff);
  const unSettledPayoff = payoff.filter((payoff) => !payoff.payoff);
  const [selectedStartDate, handleStartDateChange] = useState<DateType | null>(
    DateTime.local
  );
  const [selectedEndDate, handleEndDateChange] = useState<DateType | null>(
    DateTime.local().plus({ days: 1 })
  );
  const [totalFee, setTotalFee] = useState(0);
  const [totalCastFee, setTotalCastFee] = useState(0);
  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      dispatch(
        fetchPayoffs({
          companyId: cast.companyId,
          castId: cast.castId,
          startDate: selectedStartDate.toFormat(FORMAT_TYPE.YEAR_DAY),
          endDate: selectedEndDate.toFormat(FORMAT_TYPE.YEAR_DAY),
        })
      );
    }
  }, [selectedStartDate, selectedEndDate, dispatch, cast]);
  useEffect(() => {
    calcFee(value);
  }, [value, payoff]);
  const calcFee = (newValue: number) => {
    switch (newValue) {
      case 0:
        setTotalFee(payoff.reduce((p, x) => p + (x?.totalFee || 0), 0));
        setTotalCastFee(payoff.reduce((p, x) => p + (x?.totalCastFee || 0), 0));
        break;
      case 1:
        setTotalFee(
          unSettledPayoff.reduce((p, x) => p + (x?.totalFee || 0), 0)
        );
        setTotalCastFee(
          unSettledPayoff.reduce((p, x) => p + (x?.totalCastFee || 0), 0)
        );
        break;
      case 2:
        setTotalFee(settledPayoff.reduce((p, x) => p + (x?.totalFee || 0), 0));
        setTotalCastFee(
          settledPayoff.reduce((p, x) => p + (x?.totalCastFee || 0), 0)
        );
        break;
    }
  };
  return (
    <>
      <Box display="flex" padding={1}>
        <Box display="flex" flexDirection="column" flexBasis="100%">
          <Typography style={{ fontWeight: "bold" }}>
            総売上：{totalFee || 0}円
          </Typography>
          <Typography style={{ fontWeight: "bold" }}>
            総支給：{totalCastFee || 0}円
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <CalendarToday fontSize="small" />
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
              value={selectedStartDate}
              onChange={handleStartDateChange}
              animateYearScrolling
              format={FORMAT_TYPE.YEAR_DAY}
            />{" "}
            ~
            <DatePicker
              value={selectedEndDate}
              onChange={handleEndDateChange}
              animateYearScrolling
              format={FORMAT_TYPE.YEAR_DAY}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="全て" {...a11yProps(0)} />
            <Tab label="未精算" {...a11yProps(1)} />
            <Tab label="精算済み" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={value} index={0}>
        <PayoffItem payoff={payoff} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PayoffItem payoff={unSettledPayoff} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PayoffItem payoff={settledPayoff} />
      </TabPanel>
    </>
  );
};
export default Payoff;
