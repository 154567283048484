import React, { useState, useEffect } from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  AppointmentForm,
} from "@devexpress/dx-react-scheduler-material-ui";
import { DateTime } from "luxon";
import { useSelector, useDispatch } from "react-redux";
import useAsyncCallback from "hooks/useAsyncCallback";
import { fetchOrders } from "redux/actions/orders";
import { AppointmentContent, AppointmentTooltipContent } from "./components";
import { useHistory, useLocation } from "react-router-dom";
import { fetchShiftsByDate } from "redux/actions/shifts";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import CastShiftRes from "types/res/shift/ShiftRes";
import { Box, Button, TextField } from "@material-ui/core";
import { fetchShops } from "redux/actions/shops";

const Order = () => {
  const dispatch = useDispatch();
  const [targetShift, setTargetShift] = useState<CastShiftRes>();
  const [time, setTime] = useState("");
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const date = query.get("date");
  const shopId = query.get("shopId");
  const guestId = query.get("guestId");
  const shops = useSelector((state) => state.shops);
  const castShifts = useSelector((state) => state.shifts);
  const orders = useSelector((state) => state.orders);
  const cast = useSelector((state) => state.account.cast);
  const [currentDate, setCurrentDate] = useState(
    DateTime.local().toFormat("yyyy-MM-dd")
  );
  const [data, setData] = useState<any[]>([]);
  const history = useHistory();

  const { isReady, refresh } = useAsyncCallback(
    () =>
      dispatch(
        fetchOrders({
          castId: cast.castId,
          companyId: cast.companyId,
          startDate: DateTime.fromISO(currentDate)
            .startOf("month")
            .toFormat("yyyy-MM-dd"),
          endDate: DateTime.fromISO(currentDate)
            .endOf("month")
            .toFormat("yyyy-MM-dd"),
        })
      ),
    [currentDate, cast]
  );

  useEffect(() => {
    if (!date) return;
    dispatch(
      fetchShiftsByDate({
        companyId: cast.companyId,
        castId: cast.castId,
        startDate: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY)
          .minus({ days: 30 })
          .toFormat(FORMAT_TYPE.YEAR_DAY),
        endDate: DateTime.fromFormat(date, FORMAT_TYPE.YEAR_DAY)
          .plus({ days: 30 })
          .toFormat(FORMAT_TYPE.YEAR_DAY),
      })
    );
    dispatch(fetchShops({ companyId: cast.companyId }));
  }, [date]);

  useEffect(() => {
    if (date) {
      setCurrentDate(date);
      setTargetShift(castShifts[date]);
    }
  }, [date, castShifts]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (shopId) {
      const shop = shops.find((shop) => shop.shopId === Number(shopId));
      if (!shop) return setData(orders);
      const closingHour = shop.closingTime.split(":")?.[0];
      const openingHour = shop.openingTime.split(":")?.[0];
      if (closingHour && openingHour) {
        if (12 < Number(closingHour) && Number(closingHour) <= 24) {
          const outOfBusiness = [
            {
              id: shopId,
              startDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
                .startOf("day")
                .toJSDate(),
              endDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
                .startOf("day")
                .plus({ hours: Number(openingHour) })
                .toJSDate(),
              title: "営業時間外",
            },
            {
              id: shopId,
              startDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
                .startOf("day")
                .toJSDate(),
              endDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
                .startOf("day")
                .plus({ hours: Number(openingHour) })
                .toJSDate(),
              title: "営業時間外",
            },
          ];
          return setData([...orders, ...outOfBusiness]);
        } else {
          const outOfBusiness = [
            {
              id: shopId,
              startDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
                .startOf("day")
                .plus({ hours: Number(closingHour) })
                .toJSDate(),
              endDate: DateTime.fromFormat(currentDate, FORMAT_TYPE.YEAR_DAY)
                .startOf("day")
                .plus({ hours: Number(openingHour) })
                .toJSDate(),
              title: "営業時間外",
            },
          ];
          return setData([...orders, ...outOfBusiness]);
        }
      }
      return setData([...orders]);
    } else {
      return setData(orders);
    }
  }, [orders, shops, shopId]);

  const onCurrentDateChange = (currentDate: Date) => {
    setCurrentDate(DateTime.fromJSDate(currentDate).toFormat("yyyy-MM-dd"));
  };

  if (!isReady) return <div>Loading</div>;
  const CustomNavigationRoot = (props: any) => {
    if (date) {
      return (
        <DateNavigator.Root
          {...props}
          onNavigate={(e, nextDate) => {
            if (!targetShift) return;
            if (e === "forward") {
              const simulatedDate = DateTime.fromJSDate(
                targetShift.planWorkStart
              ).toFormat(FORMAT_TYPE.YEAR_DAY);
              const shiftIndex = Object.keys(castShifts).findIndex(
                (key) => key === simulatedDate
              );
              if (shiftIndex === -1) {
                return alert("次のシフトが登録されていません。");
              }
              const [shiftDay, currentShift] = Object.entries(castShifts)?.[
                shiftIndex
              ] || [undefined, undefined];
              if (
                currentShift &&
                currentShift.planWorkEnd &&
                DateTime.fromJSDate(currentShift.planWorkEnd).toFormat(
                  FORMAT_TYPE.YEAR_DAY
                ) !== shiftDay &&
                currentDate === shiftDay
              ) {
                return setCurrentDate(
                  DateTime.fromJSDate(currentShift.planWorkEnd).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                );
              }
              const [targetDate, nextShift] = Object.entries(castShifts)?.[
                shiftIndex + 1
              ] || [undefined, undefined];
              if (targetDate) {
                setTargetShift(nextShift);
                return setCurrentDate(targetDate);
              } else {
                return alert("次のシフトが登録されていません。");
              }
            }
            if (e === "back") {
              if (!targetShift) {
                return alert("前のシフトが登録されていません。");
              }
              const shiftIndex = Object.keys(castShifts).findIndex(
                (key) =>
                  key ===
                  DateTime.fromJSDate(targetShift.planWorkStart).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
              );
              if (shiftIndex === -1) {
                return alert("前のシフトが登録されていません。");
              }
              const [shiftDay, currentShift] = Object.entries(castShifts)?.[
                shiftIndex
              ] || [undefined, undefined];
              if (
                currentShift &&
                currentShift.planWorkEnd &&
                DateTime.fromJSDate(currentShift.planWorkEnd).toFormat(
                  FORMAT_TYPE.YEAR_DAY
                ) !== shiftDay &&
                currentDate !== shiftDay
              ) {
                return setCurrentDate(
                  DateTime.fromJSDate(currentShift.planWorkEnd)
                    .minus({ days: 1 })
                    .toFormat(FORMAT_TYPE.YEAR_DAY)
                );
              }
              const [targetDate, nextShift] = Object.entries(castShifts)?.[
                shiftIndex - 1
              ] || [undefined, undefined];
              if (targetDate) {
                if (
                  nextShift.planWorkEnd &&
                  DateTime.fromJSDate(nextShift.planWorkEnd).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  ) !== targetDate
                ) {
                  setTargetShift(nextShift);
                  return setCurrentDate(
                    DateTime.fromFormat(targetDate, FORMAT_TYPE.YEAR_DAY)
                      .plus({ days: 1 })
                      .toFormat(FORMAT_TYPE.YEAR_DAY)
                  );
                } else {
                  setTargetShift(nextShift);
                  return setCurrentDate(targetDate);
                }
              } else {
                return alert("前のシフトが登録されていません。");
              }
            }
            setCurrentDate(nextDate as string);
          }}
        />
      );
    } else {
      return <DateNavigator.Root {...props} />;
    }
  };

  const CustomOverLay = (props: any) => {
    if (date) {
      return null;
    } else {
      return <DateNavigator.Overlay {...props} />;
    }
  };

  const onClickOrderPage = () => {
    if (!time) return alert("時間を入力してください");
    history.push(
      `/newOrder?date=${currentDate}&shopId=${shopId}&guestId=${guestId}&time=${time}`
    );
  };

  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <Scheduler data={data} locale="ja-JP">
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={onCurrentDateChange}
        />

        <DayView
          startDayHour={
            currentDate === DateTime.local().toFormat("yyyy-MM-dd") &&
            DateTime.local().hour > 3
              ? DateTime.local().minus({ hours: 3 }).hour
              : 0
          }
          endDayHour={24}
        />
        <Toolbar />
        <DateNavigator
          rootComponent={CustomNavigationRoot}
          overlayComponent={CustomOverLay}
        />
        <TodayButton />
        <Appointments appointmentContentComponent={AppointmentContent} />
        <AppointmentTooltip contentComponent={AppointmentTooltipContent} />
        <AppointmentForm
          visible={false}
          onAppointmentDataChange={(appointmentData) => {
            setTime(
              appointmentData?.startDate
                ? DateTime.fromJSDate(
                    appointmentData.startDate as Date
                  ).toFormat("HH:mm")
                : ""
            );
          }}
        />
        {date && (
          <Box
            display={"flex"}
            style={{
              position: "absolute",
              zIndex: 1000,
              bottom: 0,
              padding: "20px 10px",
              backgroundColor: "white",
              opacity: 0.8,
              maxWidth: "414px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <TextField
                type={"time"}
                label={"予定IN"}
                value={time}
                variant={"outlined"}
                style={{ width: "200px" }}
                onChange={(event) => setTime(event.target.value)}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "10px" }}
                onClick={onClickOrderPage}
              >
                受注情報登録へ
              </Button>
            </Box>
          </Box>
        )}
      </Scheduler>
    </div>
  );
};

export default Order;
