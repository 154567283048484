import { createAsyncThunk } from "@reduxjs/toolkit";
import PayoffApi from "api/PayoffApi";

export const fetchPayoffs = createAsyncThunk(
  "fetchPayoffs",
  ({
    companyId,
    castId,
    startDate,
    endDate,
  }: {
    companyId: number;
    castId: number;
    startDate: string;
    endDate: string;
  }) => PayoffApi.fetchPayoffs(companyId, castId, startDate, endDate)
);
