import { Expose, Type } from "class-transformer";
import GuestRes from "../guest/GuestRes";

export default class GuestPointRes {
  @Expose() readonly guestPointId!: number;
  @Expose() readonly point!: number;
  @Expose() readonly expiredAt!: Date;
  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;
}
