import { createSlice } from "@reduxjs/toolkit";

import { fetchPayoffs } from "redux/actions/payoff";
import OrderRes from "../../types/res/order/OrderRes";

export type Payoff = OrderRes;
export type PayoffsState = Payoff[];

const initialState = [] as PayoffsState;

const payoffSlice = createSlice({
  name: "payoff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPayoffs.fulfilled, (_, { payload }) => payload);
  },
});

export default payoffSlice.reducer;
