enum OrderStatus {
  booking = "予約",
  paid = "完了",
  hold = "保留",
  cancel = "キャンセル",
  castRequest = "申請中",
  castConfirm = "確認待ち",
}

export default OrderStatus;
