import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 5%;
  background-color: rgb(40, 107, 204);
  border-bottom: solid 2px rgb(153, 153, 153);
`;

export const LinkContainer = styled(Link)<{ to: string }>`
  width: calc(100% / 3);
  text-align: center;
  padding: 3%;
`;

export const IconWrapper = styled.div`
  width: 80%;
  padding: 20% 0 12%;
  background-color: white;
  border-radius: 50%;
  margin: 0 auto;
`;

export const Label = styled.p`
  color: white;
  font-size: 12px;
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  height: 45vh;
  overflow-y: scroll;
`;

export const NotificationContainer = styled.div`
  width: 100%;
  padding: 4% 6%;
  border-bottom: solid 1px rgb(153, 153, 153);
  display: flex;
  flex-direction: column;
`;

export const NotificationTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

export const NotificationContent = styled.p`
  font-size: 12px;
  margin-top: 5px;
  white-space: break-spaces;
`;
export const NotificationRead = styled.p`
  font-size: 10px;
  color: rgb(153, 153, 153);
  display: flex;
  align-self: flex-end;
`;
