import { createSlice } from "@reduxjs/toolkit";

import ShopRes from "types/res/shop/ShopRes";
import { fetchShops } from "redux/actions/shops";

export type Shop = ShopRes;
export type ShopState = Shop[];

const initialState = [] as ShopState;

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShops.fulfilled, (_, { payload }) => payload);
  },
});

export default shopSlice.reducer;
