import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CastRes from "types/res/cast/CastRes";
import CastApi from "api/CastApi";
import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { changeAccount } from "redux/actions/account";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";
const Setting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useSelector((state) => state.account.cast.company);
  const email = useSelector((state) => state.account.cast.email);
  const [castList, setCastList] = useState<CastRes[]>([]);
  const useStyles = makeStyles(() =>
    createStyles({
      button: {},
    })
  );
  const classes = useStyles();
  useEffect(() => {
    const apiCall = async () => {
      const result = await CastApi.findByEmail(email);
      setCastList(result);
    };
    apiCall();
  }, []);
  const onClickChangeAccount = (cast: CastRes) => {
    dispatch(changeAccount({ cast }));
  };
  return (
    <Box display="flex" flexDirection="column">
      <Box margin={2}>
        <Typography>現在のログイン中企業：{company.name}</Typography>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" margin={2}>
        <Typography variant={"subtitle1"}>所属企業一覧</Typography>
        {castList.map((cast) => (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginY={1}
          >
            <Typography>{cast.company.name}</Typography>
            <Button
              variant="contained"
              className={classes.button}
              color="secondary"
              onClick={() => onClickChangeAccount(cast)}
            >
              <Typography>切り替え</Typography>
            </Button>
          </Box>
        ))}
      </Box>
      <Box marginTop={2} display={"flex"} justifyContent={"center"}>
        <Alert severity={"error"}>
          企業が追加された方は一度ログアウトし、パスワードリセットを行ってください
        </Alert>
      </Box>
      <Box marginTop={2} display={"flex"} justifyContent={"center"}>
        <Button
          variant="contained"
          className={classes.button}
          color="default"
          onClick={() => history.push("/")}
        >
          トップページへ
        </Button>
      </Box>
    </Box>
  );
};

export default Setting;
