import { Expose } from "class-transformer";

export default class CastMobileMenuRes {
  @Expose() readonly castMobileMenuId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly isShiftDisplay!: boolean;
  @Expose() readonly isScheduleDisplay!: boolean;
  @Expose() readonly isOrderDetailDisplay!: boolean;
  @Expose() readonly isAttendanceDisplay!: boolean;
  @Expose() readonly isEmergencyDisplay!: boolean;
  @Expose() readonly isPayoffDisplay!: boolean;
  @Expose() readonly isGuestNoteDisplay!: boolean;
}
