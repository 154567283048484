import { LinksWrapper } from "../Top/components";
import React from "react";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Box, Button, SvgIconTypeMap, Typography } from "@material-ui/core";
import { Call, MeetingRoom, NewReleases } from "@material-ui/icons";
import styled from "styled-components";
import { NotificationList } from "../Top";
import { CastReportType } from "../../types/enum/CastReportType";
import { useDispatch, useSelector } from "react-redux";
import { postReport } from "../../redux/actions/report";
import toastActions, { ToastType } from "../../redux/actions/toast";

type LinkType = {
  readonly type: CastReportType;
  readonly label: string;
  readonly icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
};

const links: readonly LinkType[] = [
  {
    type: CastReportType.emergency,
    label: "至急来てください",
    icon: NewReleases,
  },
  {
    type: CastReportType.contact,
    label: "連絡ください",
    icon: Call,
  },
  {
    type: CastReportType.exit,
    label: "退出します",
    icon: MeetingRoom,
  },
];
const EmergencyCall = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.cast.companyId);
  const castId = useSelector((state) => state.account.cast.castId);
  const onClick = (type: CastReportType) => async () => {
    try {
      dispatch(postReport({ companyId, req: { castId, type } }));
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    } catch (e) {
      dispatch(
        toastActions.showToast({
          text: e.message ?? "送信に失敗しました",
          type: ToastType.Failure,
        })
      );
    }
  };
  return (
    <>
      <LinksWrapper>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex="1"
        >
          {links.map((item, index) => (
            <Button key={index} onClick={onClick(item.type)} fullWidth={true}>
              <Box display="flex" alignItems="center" flex={1}>
                <TheCircle>
                  <item.icon fontSize="large" />
                </TheCircle>
                <TheTypography variant="h6">{item.label}</TheTypography>
              </Box>
            </Button>
          ))}
        </Box>
      </LinksWrapper>
      <NotificationList />
    </>
  );
};
export default EmergencyCall;

const TheCircle = styled.div`
  width: 26%;
  padding: 8%;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TheTypography = styled(Typography)`
  color: white;
  margin-left: 10px;
`;
