import { Expose, Type } from "class-transformer";
import NotificationRes from "types/res/notification/NotificationRes";
import OrderRes from "types/res/order/OrderRes";

export default class CastNotificationRes {
  @Type(() => NotificationRes)
  @Expose()
  notifications!: NotificationRes[];
  @Type(() => OrderRes)
  @Expose()
  orders!: OrderRes[];
}
