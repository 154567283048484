import RequestUtils from "../utils/RequestUtils";
import CastMobileMenuRes from "types/res/castMobileMenu/CastMobileMenuRes";

export default class CastMobileMenuApi {
  static async findOne(companyId: number) {
    return await RequestUtils.get(
      CastMobileMenuRes,
      `/castMobileMenu/findOne/${companyId}`
    );
  }
}
