import React from "react";
import styled from "styled-components";

export const OrdersWrapper = styled.div`
  width: 90%;
  padding: 16px 0;
  margin: 0 auto;
`;

export const OrderItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto 10px;
`;

export const Title = styled.div`
  width: 38%;
  font-size: 14px;
  margin-left: 12%;
`;

export const Content = styled.div`
  width: 50%;
  font-size: 14px;
`;

export const ControllerWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div`
  width: 40%;
  padding: 20% 0 12%;
  background-color: gray;
  border-radius: 50%;
  margin: 0 auto;
`;

export const Label = styled.p`
  color: white;
  font-size: 12px;
`;

export const AddOptionButton = styled.div`
  width: 50%;
  border: 1px solid black;
  border-radius: 12px;
  font-size: 12px;
  color: white;
  text-align: center;
`;

export const Switcher = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    margin-right: 8px;
  }
`;

export const OrderControllerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 16px;
`;

export const InOutContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-top: 40px;
  padding: 16px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
`;
export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 16px 0;
`;

export const AddressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 24px;
`;
