import { Expose, Type } from "class-transformer";
import NominationRes from "../nomination/NominationRes";

export default class ShopRes {
  @Expose() readonly shopId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly realName!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly email!: string;
  @Expose() readonly url!: string;
  @Expose() readonly cardRate!: number;
  @Expose() readonly pointRate!: number;
  @Expose() readonly orderAlertEmailTime!: number;
  @Expose() readonly openingTime!: string;
  @Expose() readonly closingTime!: string;
  @Expose() readonly nominationId!: number | null;
  @Expose() readonly acdCode!: string | null;
  @Type(() => NominationRes)
  @Expose()
  readonly nomination!: NominationRes;
}
