import RequestUtils from "../utils/RequestUtils";
import NominationRes from "../types/res/nomination/NominationRes";

export default class NominationApi {
  static async fetchNomination(companyId: number) {
    return await RequestUtils.getArray(
      NominationRes,
      `/nomination/findAll/${companyId}`
    );
  }
}
