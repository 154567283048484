import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "redux/actions/account";

const Container = styled.div`
  width: 260px;
  padding: ${(props) => props.theme.spacing(2.5)}px;
  > * {
    margin-bottom: ${({ theme: { spacing } }) => spacing(1)}px;
  }
`;

const Authenticated = ({ name }: { name?: string }) => {
  const dispatch = useDispatch();
  const onClickLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={onClickLogout}
      >
        Logout
      </Button>
    </>
  );
};

const Sidebar = () => {
  return (
    <Container>
      <Authenticated />
    </Container>
  );
};

export default Sidebar;
