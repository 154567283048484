import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import CarRes from "../car/CarRes";

export default class DriverRes {
  @Expose() readonly driverId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly activeEmail!: string | null;
  @Expose() readonly name!: string;
  @Expose() readonly address!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly carType!: string;
  @Expose() readonly carNumber!: string;
  @Expose() readonly carColor!: string;
  @Expose() readonly sort!: number | null;
}
