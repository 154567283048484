import { Expose, Type } from "class-transformer";
import CourseRes from "../course/CourseRes";
import OrderRes from "../order/OrderRes";

export default class NominationRes {
  @Expose() readonly nominationId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly totalFee!: number;
  @Expose() readonly castFee!: number;
  @Expose() readonly shopFee!: number;
  @Type(() => CourseRes)
  @Expose()
  readonly courses!: CourseRes[];
  @Type(() => OrderRes)
  @Expose()
  readonly orders!: OrderRes[];
}
