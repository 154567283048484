import RequestUtils from "../utils/RequestUtils";
import LoginCastReq from "../types/req/cast/LoginCastReq";
import LoginRes from "../types/res/cast/LoginRes";
import CastRes from "types/res/cast/CastRes";

export default class CastApi {
  static async login(data: LoginCastReq): Promise<LoginRes> {
    return await RequestUtils.post(LoginRes, `/auth/login`, data);
  }

  static async logout() {
    return await RequestUtils.postVoid(`/auth/logout`, undefined);
  }

  static async findByResetToken(resetToken: string) {
    return await RequestUtils.get(
      CastRes,
      `/cast/findByResetToken?token=${resetToken}`
    );
  }

  static async resetPassword(email: string, password: string) {
    return await RequestUtils.post(CastRes, `/cast/passwordReset`, {
      email,
      password,
    });
  }

  static async resetPasswordSendEmail(email: string) {
    return await RequestUtils.postVoid(`/cast/passwordReset/sendEmail`, {
      email,
    });
  }

  static async findByEmail(email: string) {
    return await RequestUtils.postArray(CastRes, `/cast/findByEmail`, {
      email,
    });
  }
}
