import RequestUtils from "../utils/RequestUtils";
import OptionRes from "../types/res/option/OptionRes";

export default class OptionApi {
  static async fetchOption(companyId: number, shopId: number) {
    return await RequestUtils.getArray(
      OptionRes,
      `/option/findAll/${companyId}/${shopId}`
    );
  }

  static async fetchOptionByCastNameId(companyId: number, castNameId: number) {
    return await RequestUtils.getArray(
      OptionRes,
      `/option/findByCastNameId/${companyId}/${castNameId}`
    );
  }
}
