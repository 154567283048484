import { createSlice } from "@reduxjs/toolkit";
import OptionRes from "types/res/option/OptionRes";
import { fetchOption, fetchOptionByCastNameId } from "redux/actions/option";

export type OptionState = OptionRes[];

const initialState = [] as OptionState;

const optionSlice = createSlice({
  name: "option",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOption.fulfilled, (_, { payload }) => payload);
    builder.addCase(
      fetchOptionByCastNameId.fulfilled,
      (_, { payload }) => payload
    );
  },
});

export default optionSlice.reducer;
