import { useCallback, useState } from "react";

enum Status {
  Init,
  Loading,
  Success,
  Error,
}

const useAsyncCallback = (asyncAction: any, dependencies: any = []) => {
  // This is like state machine. It has 'init', 'loading', 'error' and 'success'
  const [status, setStatus] = useState(Status.Init);
  const isReady = status === Status.Error || status === Status.Success;
  const isLoading = status === Status.Loading;

  const refresh = useCallback(async () => {
    setStatus(Status.Loading);
    const res = await asyncAction();

    if (res.error) {
      alert(res.error.message);
      setStatus(Status.Error);
    } else {
      setStatus(Status.Success);
    }

    return res;
    // To avoid infinite calling
  }, dependencies);

  return { refresh, isReady, isLoading };
};

export default useAsyncCallback;
