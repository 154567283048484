import HttpRequestError from "errors/HttpRequestError";
import EnvironmentUtils from "utils/EnviromentUtils";
import { plainToClass } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import { ErrorCode } from "constants/ErrorCode";
import { store } from "index";
import { logout } from "redux/actions/account";
const BASE_PATH = EnvironmentUtils.getApiUrl();

export default class RequestUtils {
  private constructor() {}

  static async post<T>(cls: ClassType<T>, path: string, data: any): Promise<T> {
    return await RequestUtils.fetchAndPlainToClass(cls, "POST", path, data);
  }

  static async get<T>(cls: ClassType<T>, path: string): Promise<T> {
    return await RequestUtils.fetchAndPlainToClass(cls, "GET", path);
  }

  static async getPlain(path: string) {
    return await RequestUtils.fetchBase("GET", path);
  }

  static async put<T>(cls: ClassType<T>, path: string, data: any): Promise<T> {
    return await RequestUtils.fetchAndPlainToClass(cls, "PUT", path, data);
  }

  static async delete<T>(
    cls: ClassType<T>,
    path: string,
    data: any
  ): Promise<T> {
    return await RequestUtils.fetchAndPlainToClass(cls, "DELETE", path, data);
  }

  static async postArray<T>(
    cls: ClassType<T>,
    path: string,
    data: any
  ): Promise<T[]> {
    return await RequestUtils.fetchAndPlainToClassArray(
      cls,
      "POST",
      path,
      data
    );
  }

  static async getArray<T>(cls: ClassType<T>, path: string): Promise<T[]> {
    return await RequestUtils.fetchAndPlainToClassArray(cls, "GET", path);
  }

  static async putArray<T>(
    cls: ClassType<T>,
    path: string,
    data: any
  ): Promise<T[]> {
    return await RequestUtils.fetchAndPlainToClassArray(cls, "PUT", path, data);
  }

  static async deleteArray<T>(
    cls: ClassType<T>,
    path: string,
    data: any
  ): Promise<T[]> {
    return await RequestUtils.fetchAndPlainToClassArray(
      cls,
      "DELETE",
      path,
      data
    );
  }

  static async postVoid(path: string, data: any) {
    await RequestUtils.fetchBase("POST", path, data);
  }

  static async getVoid(path: string) {
    await RequestUtils.fetchBase("GET", path);
  }

  static async putVoid(path: string, data: any) {
    await RequestUtils.fetchBase("PUT", path, data);
  }

  static async deleteVoid(path: string, data: any) {
    await RequestUtils.fetchBase("DELETE", path, data);
  }

  public static async getBlob(url: string, withBasePath?: boolean) {
    const res: Response = await RequestUtils.timeout(
      5 * 60 * 1000,
      fetch(withBasePath ? BASE_PATH + url : url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          // IEではGETがキャッシュされてしまうため
          "If-Modified-Since": "Thu, 01 Jun 1970 00:00:00 GMT",
        },
      })
    );
    return await RequestUtils.handleBlobResponse(res);
  }

  private static async timeout(
    ms: number,
    promise: Promise<any>
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => reject(new HttpRequestError(ErrorCode.TIMEOUT, 0)), ms);
      promise.then(resolve, reject);
    });
  }

  private static async fetchAndPlainToClass<T>(
    cls: ClassType<T>,
    method: string,
    path: string,
    data: any = undefined
  ) {
    const json = await RequestUtils.fetchBase(method, path, data);

    return plainToClass(cls, json);
  }

  private static async fetchAndPlainToClassArray<T>(
    cls: ClassType<T>,
    method: string,
    path: string,
    data: any = undefined
  ) {
    const json: unknown[] = await RequestUtils.fetchBase(method, path, data);
    return plainToClass(cls, json);
  }

  private static async fetchBase(
    method: string,
    path: string,
    data: any = undefined,
    isFormDate = false
  ) {
    try {
      const accessToken = store.getState().account?.accessToken;
      const res: Response = await RequestUtils.timeout(
        1 * 60 * 1000,
        fetch(BASE_PATH + path, {
          method: method,
          mode: "cors",
          credentials: "include",
          headers: isFormDate
            ? undefined
            : {
                "Content-Type": "application/json; charset=utf-8",
                // IEではGETがキャッシュされてしまうため
                "If-Modified-Since": "Thu, 01 Jun 1970 00:00:00 GMT",
                Authorization: `Bearer ${accessToken}`,
              },
          body: data ? JSON.stringify(data) : data,
        })
      );
      return await RequestUtils.handleJsonResponse(res);
    } catch (error) {
      alert(`${error.message}`);
      if (error.statusCode === 401) {
        // @ts-ignore
        store.dispatch(logout());
      }
      throw error;
    }
  }

  public static async postFormData<T>(
    cls: ClassType<T>,
    path: string,
    data: FormData
  ) {
    const res: Response = await RequestUtils.timeout(
      1 * 60 * 1000,
      fetch(BASE_PATH + path, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        body: data,
      })
    );
    const json = await RequestUtils.handleJsonResponse(res);
    return plainToClass(cls, json);
  }

  public static async postFormDataMultipart<T>(
    cls: ClassType<T>,
    path: string,
    formData: FormData
  ): Promise<T> {
    const res: Response = await RequestUtils.timeout(
      1 * 60 * 1000,
      fetch(BASE_PATH + path, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: undefined,
        body: formData,
      })
    );
    const json = await RequestUtils.handleJsonResponse(res);
    return plainToClass(cls, json);
  }

  private static async handleJsonResponse(res: Response) {
    if (res.ok) {
      return res.json();
    }
    const data = await res.json();
    throw new HttpRequestError(data.message, res.status);
  }

  private static async handleBlobResponse(res: Response) {
    if (res.ok) {
      return res.blob();
    }
    const data = await res.json();
    throw new HttpRequestError(data.message, res.status);
  }
}
