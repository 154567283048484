import { createSlice } from "@reduxjs/toolkit";
import CosplayRes from "types/res/cosplay/CosplayRes";
import { fetchCosplay } from "redux/actions/cosplay";

export type CosplayState = CosplayRes[];

const initialState = [] as CosplayState;

const cosplaysSlice = createSlice({
  name: "cosplays",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCosplay.fulfilled, (_, { payload }) => payload);
  },
});

export default cosplaysSlice.reducer;
