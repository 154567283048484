import CastApi from "api/CastApi";
import LoginCastReq from "types/req/cast/LoginCastReq";
import { createAsyncThunk } from "@reduxjs/toolkit";
import CastRes from "types/res/cast/CastRes";

export const signin = createAsyncThunk(
  "account/signin",
  async ({ email, password }: { email: string; password: string }) => {
    const req = new LoginCastReq({ email, password });
    return await CastApi.login(req);
  }
);

export const logout = createAsyncThunk("account/logout", async () =>
  CastApi.logout()
);

export const changeAccount = createAsyncThunk(
  "account/changeAccount",
  async ({ cast }: { cast: CastRes }) => {
    return cast;
  }
);
