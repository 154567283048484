import { createSlice } from "@reduxjs/toolkit";
import { postReport } from "../actions/report";

const initialState = {};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postReport.fulfilled, () => {});
  },
});

export default reportsSlice.reducer;
