import { DataValidationError } from "constants/DataValidationError";
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsEnum,
  IsInt,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import OrderStatus from "types/enum/OrderStatus";
import OrderResponseStatus from "types/enum/OrderResponseStatus";
import PaymentType from "types/enum/PaymentType";
import { Type } from "class-transformer";

export default class OrderBaseReq {
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  guestId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  guestCategoryId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  shopId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  castNameId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  cosplayId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  courseId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  nominationId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  notelClassId: number | null = null;

  @IsOptional()
  @IsArray()
  additionalTimeOrders: { additionalTimeId: number; count: number }[] = [];

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  additionalTimeId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  additionalTimeCount: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  areaId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  hotelId: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  outwardDriverId: number | null = null;

  @IsOptional()
  isUseOutward!: boolean;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  returnDriverId: number | null = null;

  @IsOptional()
  isUseReturn!: boolean;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  mediumId: number | null = null;

  @IsEnum(OrderStatus)
  status!: OrderStatus;

  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  orderDate!: Date;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  totalTime: number | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  departureTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  pickUpTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  planInTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  planOutTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  actualInTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  actualEndTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  requestActualInTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  requestActualEndTime: Date | null = null;

  @IsOptional()
  @Type(() => Date)
  @IsDate({ message: DataValidationError.DATA_IS_INVALID })
  returnTime: Date | null = null;

  @IsOptional()
  @IsEnum(OrderResponseStatus)
  responseStatus: OrderResponseStatus | null = null;

  @IsOptional()
  collectReceivable: boolean;

  @IsOptional()
  payoff: boolean;

  @IsOptional()
  @IsEnum(PaymentType)
  paymentType: PaymentType | null = null;

  @IsOptional()
  @MaxLength(Spec.maxLength.order.memo, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.order.memo,
    },
  })
  memo: string | null;

  @IsOptional()
  @MaxLength(Spec.maxLength.order.questionnaire, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.order.questionnaire,
    },
  })
  questionnaire: string | null = null;

  @IsOptional()
  @MaxLength(Spec.maxLength.order.orderAddress, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.order.orderAddress,
    },
  })
  orderAddress: string | null = null;

  @IsOptional()
  @MaxLength(Spec.maxLength.order.orderAddress, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.order.orderAddress,
    },
  })
  orderAddress2: string | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  score: number | null = null;

  @IsOptional()
  @IsBoolean()
  sendMailCast = false;

  @IsOptional()
  @IsBoolean()
  sendMailOutwardDriver = false;

  @IsOptional()
  @IsBoolean()
  sendMailReturnDriver = false;

  @IsOptional()
  @IsBoolean()
  sendCancelMailOldOutwardDriver = false;

  @IsOptional()
  @IsBoolean()
  sendCancelMailOldReturnDriver = false;

  @IsOptional()
  @IsBoolean()
  sendCancelMailOldCast = false;

  @IsOptional()
  @MaxLength(Spec.maxLength.order.bookEmailBody, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.order.bookEmailBody,
    },
  })
  bookEmailBody: string | null = null;

  @IsOptional()
  @MaxLength(Spec.maxLength.order.bookEmailBody, {
    message: DataValidationError.DATA_IS_TOO_LONG,
    context: {
      constraint1: Spec.maxLength.order.bookEmailBody,
    },
  })
  driverBookEmailBody: string | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  courseTime: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  returnDuration: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  outwardDuration: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  courseFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  courseShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  courseCastFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  timeFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  timeCastFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  timeShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  welfareFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  hotelFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  hotelCastFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  hotelShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  designateFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  designateCastFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  designateShopFee: number | null = null;

  // 延長
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  additionalFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  additionalShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  additionalCastFee: number | null = null;

  // 延長
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  discountFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  discountShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  discountCastFee: number | null = null;

  // オプション
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  optionFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  optionShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  optionCastFee: number | null = null;

  // コスプレ
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  cosplayFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  cosplayShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  cosplayCastFee: number | null = null;

  // 交通費
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  areaFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  areaShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  areaCastFee: number | null = null;

  // 媒体
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  mediumFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  mediumShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  mediumCastFee: number | null = null;

  // その他
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  otherFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  otherShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  otherCastFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  invoiceFee: number | null = null;

  // 交通費
  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  pointFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  cardFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  totalFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  totalShopFee: number | null = null;

  @IsOptional()
  @IsInt({ message: DataValidationError.DATA_IS_INVALID })
  totalCastFee: number | null = null;

  @IsOptional()
  addAutoOrderPoint: number | null = null;

  @IsOptional()
  addManualOrderPoint: number | null = null;

  @IsOptional()
  pointRemark: string | null = null;

  @IsOptional()
  options: number[];

  @IsOptional()
  discounts: number[];
  constructor(arg: {
    guestId: number | null;
    guestCategoryId: number | null;
    shopId: number | null;
    castNameId: number | null;
    cosplayId: number | null;
    courseId: number | null;
    notelClassId: number | null;
    nominationId: number | null;
    additionalTimeOrders: { additionalTimeId: number; count: number }[];
    additionalTimeId: number | null;
    additionalTimeCount: number | null;
    areaId: number | null;
    hotelId: number | null;
    outwardDriverId: number | null;
    returnDriverId: number | null;
    isUseOutward: boolean;
    isUseReturn: boolean;
    mediumId: number | null;
    status: OrderStatus;
    orderDate: Date;
    totalTime: number | null;
    departureTime: Date | null;
    pickUpTime: Date | null;
    planInTime: Date | null;
    planOutTime: Date | null;
    actualInTime: Date | null;
    actualEndTime: Date | null;
    requestActualInTime: Date | null;
    requestActualEndTime: Date | null;
    returnTime: Date | null;
    responseStatus: OrderResponseStatus | null;
    collectReceivable: boolean;
    payoff: boolean;
    paymentType: PaymentType | null;
    memo: string | null;
    questionnaire: string | null;
    score: number | null;
    orderAddress: string | null;
    orderAddress2: string | null;
    sendMailCast: boolean;
    sendMailReturnDriver: boolean;
    sendMailOutwardDriver: boolean;
    sendCancelMailOldOutwardDriver: boolean;
    sendCancelMailOldReturnDriver: boolean;
    sendCancelMailOldCast: boolean;
    bookEmailBody: string;
    driverBookEmailBody: string;
    courseTime: number | null;
    returnDuration: number | null;
    outwardDuration: number | null;
    courseFee: number | null;
    courseCastFee: number | null;
    courseShopFee: number | null;
    timeFee: number | null;
    timeCastFee: number | null;
    timeShopFee: number | null;
    welfareFee: number | null;
    hotelFee: number | null;
    hotelCastFee: number | null;
    hotelShopFee: number | null;
    designateFee: number | null;
    designateCastFee: number | null;
    designateShopFee: number | null;

    // 延長
    additionalFee: number | null;
    additionalShopFee: number | null;
    additionalCastFee: number | null;

    // 延長
    discountFee: number | null;
    discountShopFee: number | null;
    discountCastFee: number | null;

    // オプション
    optionFee: number | null;
    optionShopFee: number | null;
    optionCastFee: number | null;

    // コスプレ
    cosplayFee: number | null;
    cosplayShopFee: number | null;
    cosplayCastFee: number | null;

    // 交通費
    areaFee: number | null;
    areaShopFee: number | null;
    areaCastFee: number | null;

    // 媒体
    mediumFee: number | null;
    mediumShopFee: number | null;
    mediumCastFee: number | null;

    // その他
    otherFee: number | null;
    otherShopFee: number | null;
    otherCastFee: number | null;
    invoiceFee: number | null;

    cardFee: number | null;
    pointFee: number | null;
    totalFee: number | null;
    totalShopFee: number | null;
    totalCastFee: number | null;
    // ポイント
    addAutoOrderPoint: number | null;
    addManualOrderPoint: number | null;
    pointRemark: string | null;
    options: number[];
    discounts: number[];
  }) {
    arg = arg || {};
    this.guestId = arg.guestId;
    this.guestCategoryId = arg.guestCategoryId;
    this.shopId = arg.shopId;
    this.castNameId = arg.castNameId;
    this.cosplayId = arg.cosplayId;
    this.courseId = arg.courseId;
    this.nominationId = arg.nominationId;
    this.notelClassId = arg.notelClassId;
    this.additionalTimeOrders = arg.additionalTimeOrders;
    this.additionalTimeId = arg.additionalTimeId;
    this.additionalTimeCount = arg.additionalTimeCount;
    this.areaId = arg.areaId;
    this.hotelId = arg.hotelId;
    this.outwardDriverId = arg.outwardDriverId;
    this.returnDriverId = arg.returnDriverId;
    this.isUseOutward = arg.isUseOutward;
    this.isUseReturn = arg.isUseReturn;
    this.mediumId = arg.mediumId;
    this.status = arg.status;
    this.orderDate = arg.orderDate;
    this.totalTime = arg.totalTime;
    this.departureTime = arg.departureTime;
    this.pickUpTime = arg.pickUpTime;
    this.planInTime = arg.planInTime;
    this.planOutTime = arg.planOutTime;
    this.actualInTime = arg.actualInTime;
    this.actualEndTime = arg.actualEndTime;
    this.requestActualInTime = arg.requestActualInTime;
    this.requestActualEndTime = arg.requestActualEndTime;
    this.returnTime = arg.returnTime;
    this.responseStatus = arg.responseStatus;
    this.collectReceivable = arg.collectReceivable;
    this.payoff = arg.payoff;
    this.paymentType = arg.paymentType;
    this.memo = arg.memo;
    this.questionnaire = arg.questionnaire;
    this.score = arg.score;
    this.orderAddress = arg.orderAddress;
    this.orderAddress2 = arg.orderAddress2;
    this.sendMailCast = arg.sendMailCast;
    this.sendMailReturnDriver = arg.sendMailReturnDriver;
    this.sendMailOutwardDriver = arg.sendMailOutwardDriver;
    this.sendCancelMailOldReturnDriver = arg.sendCancelMailOldReturnDriver;
    this.sendCancelMailOldOutwardDriver = arg.sendCancelMailOldOutwardDriver;
    this.sendCancelMailOldCast = arg.sendCancelMailOldCast;
    this.bookEmailBody = arg.bookEmailBody;
    this.driverBookEmailBody = arg.driverBookEmailBody;
    this.courseTime = arg.courseTime;
    this.returnDuration = arg.returnDuration;
    this.outwardDuration = arg.outwardDuration;
    this.courseFee = arg.courseFee;
    this.courseCastFee = arg.courseCastFee;
    this.courseShopFee = arg.courseShopFee;
    this.timeFee = arg.timeFee;
    this.timeCastFee = arg.timeCastFee;
    this.timeShopFee = arg.timeShopFee;
    this.welfareFee = arg.welfareFee;
    this.hotelFee = arg.hotelFee;
    this.hotelCastFee = arg.hotelCastFee;
    this.hotelShopFee = arg.hotelShopFee;
    this.designateFee = arg.designateFee;
    this.designateShopFee = arg.designateShopFee;
    this.designateCastFee = arg.designateCastFee;
    this.additionalFee = arg.additionalFee;
    this.additionalShopFee = arg.additionalShopFee;
    this.additionalCastFee = arg.additionalCastFee;
    this.discountFee = arg.discountFee;
    this.discountShopFee = arg.discountShopFee;
    this.discountCastFee = arg.discountCastFee;
    this.optionFee = arg.optionFee;
    this.optionShopFee = arg.optionShopFee;
    this.optionCastFee = arg.optionCastFee;
    this.cosplayFee = arg.cosplayFee;
    this.cosplayShopFee = arg.cosplayShopFee;
    this.cosplayCastFee = arg.cosplayCastFee;
    this.areaFee = arg.areaFee;
    this.areaShopFee = arg.areaShopFee;
    this.areaCastFee = arg.areaCastFee;
    this.mediumFee = arg.mediumFee;
    this.mediumShopFee = arg.mediumShopFee;
    this.mediumCastFee = arg.mediumCastFee;
    this.otherFee = arg.otherFee;
    this.otherShopFee = arg.otherShopFee;
    this.otherCastFee = arg.otherCastFee;
    this.invoiceFee = arg.invoiceFee;
    this.cardFee = arg.cardFee;
    this.pointFee = arg.pointFee;
    this.totalFee = arg.totalFee;
    this.totalShopFee = arg.totalShopFee;
    this.totalCastFee = arg.totalCastFee;
    this.addAutoOrderPoint = arg.addAutoOrderPoint;
    this.addManualOrderPoint = arg.addManualOrderPoint;
    this.pointRemark = arg.pointRemark;
    this.options = arg.options;
    this.discounts = arg.discounts;
  }
}
