import { createSlice } from "@reduxjs/toolkit";

import {
  addAddress,
  addOptions,
  fetchOrders,
  putScheduleIn,
  putScheduleOut,
} from "redux/actions/orders";
import OrderRes from "types/res/order/OrderRes";
import DateTimeUtils from "utils/DateTimeUtils";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";

export type Order = OrderRes & AppointmentModel;
export type OrderState = Order[];

const initialState = [] as OrderState;
const configureOrders = (raw: OrderRes[]): OrderState => {
  return raw.map((orderRes) => ({
    ...orderRes,
    startDate: orderRes.departureTime
      ? DateTimeUtils.toFormatAsLocalTimezone(
          orderRes.departureTime,
          "yyyy-MM-dd'T'HH:mm"
        )
      : "",
    endDate: orderRes.planOutTime
      ? DateTimeUtils.toFormatAsLocalTimezone(
          orderRes.planOutTime,
          "yyyy-MM-dd'T'HH:mm"
        )
      : "",
  }));
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.fulfilled, (_, { payload }) =>
      configureOrders(payload)
    );
    builder.addCase(putScheduleIn.fulfilled, (state, { payload }) => {
      const orders = configureOrders([payload]);
      const index = state.findIndex(
        (order) => order.orderId === payload.orderId
      );
      state.splice(index, 1, orders[0]);
    });
    builder.addCase(putScheduleOut.fulfilled, (state, { payload }) => {
      const orders = configureOrders([payload]);
      const index = state.findIndex(
        (order) => order.orderId === payload.orderId
      );
      state.splice(index, 1, orders[0]);
    });
    builder.addCase(addOptions.fulfilled, (state, { payload }) => {
      const orders = configureOrders([payload]);
      const index = state.findIndex(
        (order) => order.orderId === payload.orderId
      );
      state.splice(index, 1, orders[0]);
    });
    builder.addCase(addAddress.fulfilled, (state, { payload }) => {
      const orders = configureOrders([payload]);
      const index = state.findIndex(
        (order) => order.orderId === payload.orderId
      );
      state.splice(index, 1, orders[0]);
    });
  },
});

export default ordersSlice.reducer;
