const Spec = {
  maxLength: {
    cast: {
      email: 256,
      name: 128,
      password: 32,
      address: 1024,
    },
    castShift: {
      staffMemo: 63356,
      castMemo: 63356,
    },
    order: {
      orderAddress: 2048,
      memo: 63356,
      questionnaire: 63356,
      bookEmailBody: 63356,
    },
  },
};
export default Spec;
