import { combineReducers } from "redux";
import modals from "./modals";
import account from "./account";
import shifts from "./shifts";
import toast from "./toast";
import orders from "./orders";
import notifications from "./notifications";
import payoff from "./payoff";
import guestNotes from "./guestnotes";
import report from "./report";
import shops from "./shops";
import "react-redux";
import company from "redux/reducers/company";
import guest from "redux/reducers/guest";
import additionalTime from "redux/reducers/additionalTime";
import area from "redux/reducers/area";
import cosplay from "redux/reducers/cosplay";
import course from "redux/reducers/course";
import hotel from "redux/reducers/hotel";
import nomination from "redux/reducers/nomination";
import option from "redux/reducers/option";
import notelClass from "redux/reducers/notelClass";

export type AppState = ReturnType<typeof reducer>;

declare module "react-redux" {
  interface DefaultRootState extends AppState {}
}

const reducer = combineReducers({
  account,
  additionalTime,
  area,
  company,
  cosplay,
  course,
  guest,
  guestNotes,
  hotel,
  modals,
  nomination,
  notelClass,
  notifications,
  option,
  shifts,
  orders,
  toast,
  payoff,
  report,
  shops,
});

export default reducer;
