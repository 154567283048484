import { createAsyncThunk } from "@reduxjs/toolkit";
import OptionApi from "api/OptionApi";

export const fetchOption = createAsyncThunk(
  "fetchOption",
  ({ companyId, shopId }: { companyId: number; shopId: number }) =>
    OptionApi.fetchOption(companyId, shopId)
);

export const fetchOptionByCastNameId = createAsyncThunk(
  "fetchOptionByCastNameId",
  ({ companyId, castNameId }: { companyId: number; castNameId: number }) =>
    OptionApi.fetchOptionByCastNameId(companyId, castNameId)
);
