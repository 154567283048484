import { createSlice } from "@reduxjs/toolkit";
import AreaRes from "types/res/area/AreaRes";
import { fetchArea } from "redux/actions/area";

export type AreaState = AreaRes[];

const initialState = [] as AreaState;

const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArea.fulfilled, (_, { payload }) => payload);
  },
});

export default areaSlice.reducer;
