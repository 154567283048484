import actionCreatorFactory from "typescript-fsa";

export enum ToastType {
  Success,
  Failure,
}

const actionCreator = actionCreatorFactory();

export type ShowToastPayloadType = {
  text: string;
  type: ToastType;
};

const toastActions = {
  showToast: actionCreator<ShowToastPayloadType>("SHOW_TOAST"),
  hideToast: actionCreator("HIDE_TOAST"),
};

export default toastActions;
