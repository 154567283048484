import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import DateTimeUtils from "utils/DateTimeUtils";
import {
  InOutContainer,
  OptionContainer,
  OrderControllerContainer,
  OptionListContainer,
  AddressContainer,
} from "./components";
import useAsyncCallback from "hooks/useAsyncCallback";
import {
  addAddress,
  addOptions,
  fetchOrders,
  putScheduleIn,
  putScheduleOut,
  updateStatus,
} from "redux/actions/orders";
import OrderInfo from "./OrderInfo";
import OrderSwitcher from "./OrderSwitcher";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import BackdropSpinner from "components/BackdropSpinner";
import OptionRes from "types/res/option/OptionRes";
import toastActions, { ToastType } from "redux/actions/toast";
import { useParams } from "react-router-dom";
import { fetchOptionByCastNameId } from "redux/actions/option";
import OrderStatus from "types/enum/OrderStatus";

const InOut = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const companyId = useSelector((state) => state.account.cast.companyId);
  const castId = useSelector((state) => state.account.cast.castId);
  const cast = useSelector((state) => state.account.cast);
  const orders = useSelector((state) => state.orders);
  const castOptions = useSelector((state) => state.option);
  const [options, setOptions] = useState<OptionRes[]>([]);
  const [selectOptions, setSelectOptions] = useState<number[]>([]);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [address, setAddress] = useState("");
  const startDate = DateTimeUtils.local()
    .minus({ month: 1 })
    .startOf("month")
    .toString();
  const endDate = DateTimeUtils.local()
    .plus({ month: 1 })
    .endOf("month")
    .toString();
  const [currentIndex, setCurrentIndex] = useState<number>();
  const currentOrder = currentIndex !== undefined ? orders[currentIndex] : null;
  const { isReady, refresh } = useAsyncCallback(
    () =>
      dispatch(
        fetchOrders({
          companyId,
          castId,
          startDate,
          endDate,
          orderId: orderId ? Number(orderId) : undefined,
        })
      ),
    [startDate, endDate, companyId, castId, orderId]
  );

  const { isLoading: isLoadingIn, refresh: postIn } = useAsyncCallback(
    () =>
      dispatch(
        putScheduleIn({
          companyId,
          orderId: currentOrder!.orderId,
        })
      ),
    [currentOrder, companyId]
  );

  const { isLoading: isLoadingOut, refresh: postOut } = useAsyncCallback(
    () =>
      dispatch(
        putScheduleOut({
          companyId,
          orderId: currentOrder!.orderId,
        })
      ),
    [currentOrder, companyId]
  );

  const {
    refresh: postOptions,
    isLoading: isPostingOptions,
  } = useAsyncCallback(
    () =>
      dispatch(
        addOptions({
          companyId,
          orderId: currentOrder!.orderId,
          options: selectOptions,
        })
      ),
    [currentOrder, selectOptions, companyId]
  );

  const {
    refresh: postAddress,
    isLoading: isPostingAddress,
  } = useAsyncCallback(
    () =>
      dispatch(
        addAddress({
          companyId,
          orderId: currentOrder!.orderId,
          address: address,
        })
      ),
    [currentOrder, address, companyId]
  );

  const {
    isLoading: isLoadingCastConfirm,
    refresh: castConfirm,
  } = useAsyncCallback(
    () =>
      dispatch(
        updateStatus({
          companyId,
          orderId: currentOrder!.orderId,
          status: "booking" as OrderStatus,
        })
      ),
    [currentOrder, companyId]
  );

  const {
    isLoading: isLoadingCastCancel,
    refresh: castCancel,
  } = useAsyncCallback(
    () =>
      dispatch(
        updateStatus({
          companyId,
          orderId: currentOrder!.orderId,
          status: "castRequest" as OrderStatus,
        })
      ),
    [currentOrder, companyId]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (!currentOrder?.castNameId) return;
    dispatch(
      fetchOptionByCastNameId({
        companyId,
        castNameId: currentOrder?.castNameId,
      })
    );
  }, [currentOrder]);

  useEffect(() => {
    setOptions(castOptions || []);
  }, [castOptions, currentOrder]);

  useEffect(() => {
    setSelectOptions(
      currentOrder?.options?.map((option) => option.optionId) || []
    );
  }, [currentOrder]);

  useEffect(() => {
    if (orderId) {
      return setCurrentIndex(0);
    }
    if (currentIndex !== undefined || orders.length === 0) return;
    const current = DateTimeUtils.local().toSeconds();
    let index = orders.findIndex((order) => {
      if (!order.planInTime || !order.planOutTime) return;
      return (
        DateTimeUtils.fromJSDate(order.planInTime).toSeconds() < current &&
        current < DateTimeUtils.fromJSDate(order.planOutTime).toSeconds()
      );
    });

    if (index === -1) {
      const sortedIndex = _.sortedIndex(
        orders.map((order) => {
          if (order.planInTime) {
            return DateTimeUtils.fromJSDate(order.planInTime).toSeconds();
          }
        }),
        current
      );
      index = Math.max(0, Math.min(sortedIndex, orders.length - 1));
    }
    setCurrentIndex(index);
  }, [orders, currentIndex, orderId]);

  useEffect(() => {
    setAddress(currentOrder?.orderAddress || "");
  }, [currentOrder]);

  const onClickBackButton = () => {
    setCurrentIndex((prev) => Math.max(prev! - 1, 0));
  };

  const onClickForwardButton = () => {
    setCurrentIndex((prev) => Math.min(prev! + 1, orders.length - 1));
  };

  const onClickIn = async () => {
    const inPost = await postIn();
    const address = await postAddress();
    if (!inPost.error && !address.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
      dispatch(
        fetchOrders({
          companyId,
          castId,
          startDate,
          endDate,
          orderId: orderId ? Number(orderId) : undefined,
        })
      );
    }
  };

  const onClickOut = async () => {
    const res = await postOut();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  const onClickCastConfirm = async () => {
    const confirm = window.confirm("承認しますか？");
    if (!confirm) return;
    const res = await castConfirm();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  const onClickCastCancel = async () => {
    const confirm = window.confirm("本当にキャンセルしますか？");
    if (!confirm) return;
    const res = await castCancel();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  const onClickAddOption = () => {
    setDisplayOptions((prev) => !prev);
  };

  const onClickSubmitAddress = async () => {
    const res = await postAddress();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  const onClickSubmitOption = async () => {
    const res = await postOptions();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  const onClickRefresh = () => {
    refresh();
  };

  if (!isReady) return <div>Loading...</div>;
  if (orders.length === 0 || !currentOrder) return <div>予約がありません</div>;

  return (
    <>
      <div>
        <OrderInfo order={currentOrder} />

        <OrderControllerContainer>
          <OrderSwitcher
            onClickBackButton={onClickBackButton}
            onClickForwardButton={onClickForwardButton}
            disabledBackButton={currentIndex === 0}
            disabledForwardButton={currentIndex === orders.length - 1}
          />
          <Button
            onClick={onClickAddOption}
            disabled={currentOrder.payoff}
            color="primary"
            variant="contained"
          >
            オプション追加
          </Button>
        </OrderControllerContainer>
        {displayOptions && (
          <OptionContainer>
            <Typography variant="subtitle1">オプション一覧</Typography>
            {options.map((option) => (
              <OptionListContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={option.name}
                      checked={selectOptions.includes(option.optionId)}
                      onChange={() => {
                        setSelectOptions((prev) =>
                          !prev.includes(option.optionId)
                            ? [...prev, option.optionId]
                            : prev.filter((id) => id !== option.optionId)
                        );
                      }}
                    />
                  }
                  label={option.name}
                />
              </OptionListContainer>
            ))}
            <Button
              onClick={onClickSubmitOption}
              color="primary"
              variant="contained"
              disabled={currentOrder.payoff}
              style={{ width: "200px" }}
            >
              送信
            </Button>
          </OptionContainer>
        )}
        <AddressContainer>
          <TextField
            variant="outlined"
            placeholder="部屋番号を記載"
            value={address}
            onChange={(event) => setAddress(event.target.value)}
          />
          <Button
            onClick={onClickSubmitAddress}
            disabled={currentOrder.payoff}
            color="primary"
            variant="contained"
          >
            住所更新
          </Button>
        </AddressContainer>
        <InOutContainer>
          {(currentOrder?.status as string) === "castRequest" ? (
            <>
              <Button
                onClick={onClickCastConfirm}
                color="secondary"
                variant="contained"
              >
                承認
              </Button>
              <Button
                onClick={onClickCastCancel}
                color="primary"
                variant="contained"
              >
                キャンセル
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClickIn} color="primary" variant="contained">
                In
              </Button>
              <Button
                onClick={onClickOut}
                color="primary"
                variant="contained"
                disabled={
                  !(
                    currentOrder?.requestActualInTime ||
                    currentOrder?.actualInTime
                  )
                }
              >
                Out
              </Button>
            </>
          )}
        </InOutContainer>
        <Box display={"flex"} justifyContent={"center"} marginY={2}>
          <Button color="primary" variant="contained" onClick={onClickRefresh}>
            データ更新
          </Button>
        </Box>
      </div>
      <BackdropSpinner
        isLoading={isLoadingIn || isLoadingOut || isPostingOptions}
      />
    </>
  );
};

export default InOut;
