import { Expose, Type } from "class-transformer";
import AreaRes from "../area/AreaRes";

export default class HotelRes {
  @Expose() readonly hotelId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly kana!: string;
  @Expose() readonly address!: string | null;
  @Expose() readonly tel!: string | null;
  @Expose() readonly price!: string | null;
  @Expose() readonly memo!: string | null;
  @Expose() readonly sort!: number | null;
  @Type(() => AreaRes)
  @Expose()
  readonly areas!: AreaRes[];
}
