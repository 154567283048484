import { Expose, Type } from "class-transformer";
import GuestCategoryRes from "../guestCategory/GuestCategoryRes";
import GuestPointRes from "../guestPoint/GuestPointRes";
import OrderRes from "../order/OrderRes";
import GuestGuestCategoryShopRes from "types/res/guestGuestCategoryShop/GuestGuestCategoryShopRes";

export default class GuestRes {
  @Expose() readonly guestId!: number;
  @Expose() readonly email!: string | null;
  @Expose() readonly name!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly activeTel!: string | null;
  @Expose() readonly address!: string | null;
  @Expose() readonly memo!: string | null;
  @Expose() readonly isSentMail!: boolean;
  @Expose()
  readonly guestCategoryId!: number | null;
  @Type(() => GuestCategoryRes)
  @Expose()
  readonly guestCategories!: GuestCategoryRes[];
  @Type(() => GuestCategoryRes)
  @Expose()
  readonly guestCategory!: GuestCategoryRes | null;
  @Type(() => GuestPointRes)
  @Expose()
  readonly guestPoints!: GuestPointRes[];
  @Type(() => OrderRes)
  @Expose()
  readonly orders!: OrderRes[];
  @Type(() => GuestGuestCategoryShopRes)
  @Expose()
  readonly guestGuestCategoryShops!: GuestGuestCategoryShopRes[];
}
