import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import CompanyRes from "types/res/company/CompanyRes";
import CastNameRes from "../castName/CastNameRes";

export default class CastRes {
  @Expose() readonly castId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly email!: string;
  @Expose() readonly name!: string;
  @Expose() readonly address!: string;
  @Type(() => Date)
  @Expose()
  readonly birthday!: Date;
  @Expose() readonly bodyHeight!: number | null;
  @Expose() readonly bust!: number | null;
  @Expose() readonly waist!: number | null;
  @Expose() readonly hip!: number | null;
  @Type(() => CompanyRes)
  @Expose()
  readonly company!: CompanyRes;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
  @Type(() => CastNameRes)
  @Expose()
  readonly castNames!: CastNameRes[];
}
