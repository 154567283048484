import { createAsyncThunk } from "@reduxjs/toolkit";
import GuestNoteApi from "api/GuestNoteApi";
import UpdateGuestNoteReq from "types/req/guestNote/UpdateGuestNoteReq";

export const fetchGuestNotes = createAsyncThunk(
  "fetchGuestNotes",
  ({
    companyId,
    startDate,
    endDate,
  }: {
    companyId: number;
    startDate: string;
    endDate: string;
  }) => GuestNoteApi.fetchGuestNotes(companyId, startDate, endDate)
);

export const fetchOneGuestNote = createAsyncThunk(
  "fetchOneGuestNote",
  ({ companyId, guestNoteId }: { companyId: number; guestNoteId: number }) =>
    GuestNoteApi.fetchOneGuestNote(companyId, guestNoteId)
);

export const putGuestNote = createAsyncThunk(
  "putGuestNote",
  ({ companyId, req }: { companyId: number; req: UpdateGuestNoteReq }) =>
    GuestNoteApi.putGuestNote(companyId, req)
);
