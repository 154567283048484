import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneGuestNote, putGuestNote } from "redux/actions/guestnotes";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  GuestNoteBasicInfo,
  GuestNoteDateInput,
  GuestNoteSelectInput,
  GuestNoteTextInput,
} from "./components";
import GuestRank from "../../types/enum/GuestRank";
import DateTimeUtils, { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import useAsyncCallback from "hooks/useAsyncCallback";
import toastActions, { ToastType } from "redux/actions/toast";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import useModal from "hooks/useModal";
import Modal from "components/Modal";
import ShopRes from "types/res/shop/ShopRes";
import { fetchShiftsByDate } from "redux/actions/shifts";
import { fetchShops } from "redux/actions/shops";

const GuestNote = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const cast = useSelector((state) => state.account.cast);
  const guestNote = useSelector((state) => state.guestNotes)[0];
  const [formData, setFormData] = useState<any>({});
  const [castOrderModal, setCastOrderShow] = useModal("castOrder");

  useEffect(() => {
    if (!id) return;
    dispatch(
      fetchOneGuestNote({ companyId: cast.companyId, guestNoteId: Number(id) })
    );
  }, [dispatch, id, cast]);

  useEffect(() => {
    setFormData(guestNote);
  }, [guestNote]);

  const onChangeForm = (
    key: string,
    handler: (value: any) => any = (value: any) => value
  ) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >
  ) => {
    event.persist();
    setFormData((prev: any) => ({
      ...prev,
      [key]: handler(event.target.value),
    }));
  };

  const { isLoading: isLoadingIn, refresh: putIn } = useAsyncCallback(
    () =>
      dispatch(
        putGuestNote({
          companyId: cast.companyId,
          req: { ...formData, guestNoteId: guestNote.guestNoteId },
        })
      ),
    [formData]
  );
  const submitGuestNote = async () => {
    const res = await putIn();
    if (!res.error) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
  };
  if (!guestNote) return <div>loading....</div>;

  return (
    <Box>
      <Box display="flex" padding={2} flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "bold", marginBottom: "10px" }}
          >
            基本情報
          </Typography>
          <Box display="flex">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setCastOrderShow(true);
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>姫予約</Typography>
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              style={{ marginLeft: "10px" }}
              to={`/orderHistory/${guestNote.guestNoteId}`}
            >
              利用履歴
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          style={{ backgroundColor: "#eaeaea" }}
          flexDirection="column"
          padding={2}
        >
          <GuestNoteBasicInfo
            title="前回利用日"
            value={
              DateTimeUtils.toFormatAsLocalTimezone(
                guestNote.orders[0]?.createdAt,
                FORMAT_TYPE.YEAR_DAY
              ) || "未設定"
            }
          />
          <GuestNoteBasicInfo
            title="前回利用コース"
            value={guestNote.orders[0]?.course?.name || "未設定"}
          />
          <GuestNoteBasicInfo
            title="前回利用場所"
            value={guestNote.orders[0]?.orderAddress || "未設定"}
          />
          <GuestNoteBasicInfo
            title="前回指名種"
            value={guestNote.orders[0]?.nomination?.name || "未設定"}
          />
          <GuestNoteBasicInfo title="顧客名" value={guestNote.guest.name} />
          <GuestNoteTextInput
            title="あだ名"
            value={formData?.nickName || ""}
            onChange={onChangeForm("nickName")}
          />
          <GuestNoteSelectInput
            title="顧客ランク"
            value={formData?.guestRank || ""}
            onChange={onChangeForm("guestRank")}
            selectProps={Object.entries(GuestRank).map(([key, value]) => ({
              id: key,
              name: value,
            }))}
          />
          <GuestNoteDateInput
            title="誕生日"
            value={
              formData?.birthday
                ? DateTime.fromJSDate(formData?.birthday).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                : ""
            }
            onChange={onChangeForm("birthday", (value) =>
              DateTime.fromFormat(value, FORMAT_TYPE.YEAR_DAY).toJSDate()
            )}
          />
          <GuestNoteTextInput
            title="性格"
            value={formData?.personality || ""}
            onChange={onChangeForm("personality")}
          />
          <GuestNoteTextInput
            title="体型"
            value={formData?.bodyType || ""}
            onChange={onChangeForm("bodyType")}
          />
          <GuestNoteTextInput
            title="仕事"
            value={formData?.work || ""}
            onChange={onChangeForm("work")}
          />
          <GuestNoteTextInput
            title="ペット"
            value={formData?.pet || ""}
            onChange={onChangeForm("pet")}
          />
          <GuestNoteTextInput
            title="タバコ"
            value={formData?.cigarette || ""}
            onChange={onChangeForm("cigarette")}
          />
          <GuestNoteTextInput
            title="自宅の種類"
            value={formData?.home || ""}
            onChange={onChangeForm("home")}
          />
          <GuestNoteTextInput
            title="住所"
            value={formData?.address || ""}
            onChange={onChangeForm("address")}
          />
          <GuestNoteTextInput
            title="好きなタイプ"
            value={formData?.favoriteType || ""}
            onChange={onChangeForm("favoriteType")}
          />
          <GuestNoteTextInput
            title="好きな食べ物"
            value={formData?.favoriteFood || ""}
            onChange={onChangeForm("favoriteFood")}
          />
          <GuestNoteTextInput
            title="好きな色"
            value={formData?.favoriteColor || ""}
            onChange={onChangeForm("favoriteColor")}
          />
          <GuestNoteTextInput
            title="アレルギー"
            value={formData?.allergies || ""}
            onChange={onChangeForm("allergies")}
          />
          <GuestNoteTextInput
            title="利用頻度"
            value={formData?.usingFrequency || ""}
            onChange={onChangeForm("usingFrequency")}
          />
          <GuestNoteTextInput
            title="備考"
            value={formData?.remarks || ""}
            onChange={onChangeForm("remarks")}
          />
        </Box>
      </Box>
      <Box display="flex" padding={2} flexDirection="column">
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", marginBottom: "10px" }}
        >
          接客詳細
        </Typography>
        <Box
          display="flex"
          style={{ backgroundColor: "#eaeaea" }}
          flexDirection="column"
          padding={2}
        >
          <GuestNoteTextInput
            title="好みのプレイ"
            value={formData?.favoritePlay || ""}
            onChange={onChangeForm("favoritePlay")}
          />
          <GuestNoteTextInput
            title="性器のサイズ"
            value={formData?.genitalSize || ""}
            onChange={onChangeForm("genitalSize")}
          />
          <GuestNoteTextInput
            title="性器の状態"
            value={formData?.genitalState || ""}
            onChange={onChangeForm("genitalState")}
          />
          <GuestNoteTextInput
            title="プレイ回数"
            value={formData?.playNum || ""}
            onChange={onChangeForm("playNum")}
          />
          <GuestNoteTextInput
            title="性感帯"
            value={formData?.erogenousZone || ""}
            onChange={onChangeForm("erogenousZone")}
          />
          <GuestNoteTextInput
            title="本番要求"
            value={formData?.request || ""}
            onChange={onChangeForm("request")}
          />
        </Box>
      </Box>
      <Box padding={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={submitGuestNote}
        >
          保存
        </Button>
      </Box>
      {castOrderModal.show && (
        <CastOrderModal
          show={castOrderModal.show}
          setCastOrderShow={setCastOrderShow}
        />
      )}
    </Box>
  );
};

type CastOrderModalProps = {
  show: boolean;
  setCastOrderShow: any;
};
const CastOrderModal = ({ show, setCastOrderShow }: CastOrderModalProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companyId = useSelector((state) => state.account.cast.companyId);
  const castId = useSelector((state) => state.account.cast.castId);
  const castShifts = useSelector((state) => state.shifts);
  const shops = useSelector((state) => state.shops);
  const guestNote = useSelector((state) => state.guestNotes)[0];
  const [isLoading, setIsLoading] = useState(false);
  const [selectShop, setSelectShop] = useState<ShopRes>();
  const [selectDate, setSelectDate] = useState(DateTime.local().toJSDate());

  useEffect(() => {
    dispatch(fetchShops({ companyId }));
  }, []);

  useEffect(() => {
    dispatch(
      fetchShiftsByDate({
        companyId,
        castId,
        startDate: DateTime.local().toFormat(FORMAT_TYPE.YEAR_DAY),
        endDate: DateTime.local()
          .plus({ days: 30 })
          .toFormat(FORMAT_TYPE.YEAR_DAY),
      })
    );
  }, [selectDate]);

  const onClickTimeSelect = () => {
    if (!selectShop || !selectDate) alert("店舗と日付を選択してください");
    history.push(
      "/order?shopId=" +
        selectShop?.shopId +
        "&date=" +
        DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY) +
        "&guestId=" +
        guestNote.guest.guestId
    );
  };

  return (
    <Modal show={show || false} setShow={setCastOrderShow} title="姫予約">
      <Box display={"flex"} flexDirection={"column"}>
        <Box>
          <InputLabel>日付</InputLabel>
          <Select
            variant="outlined"
            style={{ marginTop: "8px" }}
            fullWidth
            value={DateTime.fromJSDate(selectDate).toFormat(
              FORMAT_TYPE.YEAR_DAY
            )}
            onChange={(event) =>
              setSelectDate(
                DateTime.fromFormat(
                  event.target.value as string,
                  FORMAT_TYPE.YEAR_DAY
                ).toJSDate()
              )
            }
          >
            {Object.keys(castShifts)
              .sort((a, b) => (a > b ? 1 : -1))
              .map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
          </Select>
        </Box>
        <Box>
          <InputLabel>店舗</InputLabel>
          <Select
            variant="outlined"
            style={{ marginTop: "8px" }}
            fullWidth
            value={selectShop?.shopId || ""}
            onChange={(event) =>
              setSelectShop(
                shops.find((shop) => shop.shopId === Number(event.target.value))
              )
            }
          >
            {castShifts[
              DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY)
            ]?.shops?.map((shop) => (
              <MenuItem key={shop.shopId} value={shop.shopId}>
                {shop.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickTimeSelect}
          style={{ marginTop: "12px" }}
        >
          時間選択へ
        </Button>
      </Box>
    </Modal>
  );
};

export default GuestNote;
