import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NewReleasesIcon from "@material-ui/icons/NewReleases"; // 新着情報
import CreateIcon from "@material-ui/icons/Create"; // シフト入力
import TodayIcon from "@material-ui/icons/Today"; // スケジュール
import LockOpenIcon from "@material-ui/icons/LockOpen"; // IN・OUT
import AlarmOnIcon from "@material-ui/icons/AlarmOn"; // 出勤確認
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant"; // 緊急通報
import LocalAtmIcon from "@material-ui/icons/LocalAtm"; // 精算
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"; // お客様ノート
import SettingsIcon from "@material-ui/icons/Settings";
import {
  fetchNotifications,
  readNotifications,
} from "redux/actions/notifications";
import {
  LinksWrapper,
  LinkContainer,
  IconWrapper,
  Label,
  NotificationWrapper,
  NotificationContainer,
  NotificationTitle,
  NotificationContent,
  NotificationRead,
} from "./components";
import useAsyncCallback from "hooks/useAsyncCallback";
import { Box, Button, SvgIconTypeMap, Typography } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import OrderRes from "types/res/order/OrderRes";
import NotificationRes from "types/res/notification/NotificationRes";
import { DateTime } from "luxon";
import EnumUtils from "utils/EnumUtils";
import OrderStatus from "types/enum/OrderStatus";
import { Link } from "react-router-dom";
import { castConfirm, updateStatus } from "redux/actions/orders";
import CastMobileMenuRes from "types/res/castMobileMenu/CastMobileMenuRes";
import CastMobileMenuApi from "api/CastMobileMenuApi";
import { set } from "lodash";

type LinkType = {
  readonly href: string;
  readonly label: string;
  readonly icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  readonly key: string;
};

const links: readonly LinkType[] = [
  {
    href: "/news",
    label: "新着情報",
    icon: NewReleasesIcon,
    key: "companyId",
  },
  {
    href: "/shift",
    label: "シフト入力",
    icon: CreateIcon,
    key: "isShiftDisplay",
  },
  {
    href: "/order",
    label: "スケジュール",
    icon: TodayIcon,
    key: "isScheduleDisplay",
  },
  {
    href: "/inout",
    label: "予約詳細",
    icon: LockOpenIcon,
    key: "isOrderDetailDisplay",
  },
  {
    href: "/shift_confirm",
    label: "出勤確認",
    icon: AlarmOnIcon,
    key: "isAttendanceDisplay",
  },
  {
    href: "/emergency",
    label: "緊急通報",
    icon: NotificationImportantIcon,
    key: "isEmergencyDisplay",
  },
  {
    href: "/payoff",
    label: "精算",
    icon: LocalAtmIcon,
    key: "isPayoffDisplay",
  },
  {
    href: "/guestNoteList",
    label: "お客様ノート",
    icon: AssignmentIndIcon,
    key: "isGuestNoteDisplay",
  },
  {
    href: "/setting",
    label: "企業切り替え",
    icon: SettingsIcon,
    key: "companyId",
  },
];

export const NotificationList = () => {
  const dispatch = useDispatch();
  const castNotification = useSelector((state) => state.notifications);
  const companyId = useSelector((state) => state.account.cast.companyId);

  const { isReady, refresh } = useAsyncCallback(
    () => dispatch(fetchNotifications(companyId)),
    [companyId]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (
      !castNotification ||
      !castNotification.notifications?.filter(
        (notification) => notification.notificationReads.length === 0
      ).length
    ) {
      return;
    }
    dispatch(
      readNotifications({
        companyId,
        req: castNotification.notifications
          .filter((notification) => notification.notificationReads.length === 0)
          .map((notification) => notification.notificationId),
      })
    );
  }, [castNotification]);

  if (!isReady) return <div>Loading...</div>;
  if (!castNotification)
    return (
      <Box display="flex" justifyContent="center" padding={1}>
        お知らせはありません
      </Box>
    );
  return (
    <NotificationWrapper>
      {Object.values(castNotification)
        .flat()
        .sort((a, b) => {
          // aが受注の場合、bがおしらせの場合
          if ("orderId" in a && "notificationId" in b) {
            return a.orderDate < b.startDate ? 1 : -1;
          }
          // aがお知らせの場合、bが受注の場合
          if ("notificationId" in a && "orderId" in b) {
            return a.startDate < b.orderDate ? 1 : -1;
          }
          // a,bともに受注の場合
          if ("orderId" in a && "orderId" in b) {
            return a.orderDate < b.orderDate ? 1 : -1;
          }
          return a.startDate < b.startDate ? 1 : -1;
          // a,bともにお知らせの場合
        })
        .map((item: OrderRes | NotificationRes) => {
          if ("orderId" in item) {
            return <OrderItem order={item as OrderRes} />;
          } else {
            const notification = item as NotificationRes;
            return (
              <NotificationItem
                startDate={
                  notification.startDate
                    ? DateTimeUtils.toFormatAsLocalTimezone(
                        notification.startDate,
                        "yyyy.MM.dd"
                      )
                    : ""
                }
                title={notification.title}
                content={notification.content}
                isRead={notification.notificationReads?.some(
                  (read: any) =>
                    read.notificationId === notification.notificationId
                )}
                key={notification.notificationId}
              />
            );
          }
        })}
    </NotificationWrapper>
  );
};

const OrderItem = ({ order }: { order: OrderRes }) => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.cast.companyId);
  const onClickCancel = async () => {
    const confirm = window.confirm("本当にキャンセルしますか？");
    if (!confirm) return;
    await dispatch(
      updateStatus({
        companyId,
        orderId: order.orderId,
        status: "cancel" as OrderStatus,
      })
    );
    await dispatch(fetchNotifications(companyId));
  };

  const onClickApprove = async () => {
    const confirm = window.confirm("承認しますか？");
    if (!confirm) return;
    await dispatch(
      updateStatus({
        companyId,
        orderId: order.orderId,
        status: "booking" as OrderStatus,
      })
    );
    await dispatch(fetchNotifications(companyId));
  };

  const onClickConfirm = async () => {
    await dispatch(
      castConfirm({
        companyId,
        orderId: order.orderId,
      })
    );
    await dispatch(
      updateStatus({
        companyId,
        orderId: order.orderId,
        status: "booking" as OrderStatus,
      })
    );
    await dispatch(fetchNotifications(companyId));
  };

  return (
    <NotificationContainer>
      <NotificationTitle>
        {DateTime.fromJSDate(order.orderDate).toFormat("yyyy.MM.dd")}{" "}
        {EnumUtils.mapToEnum(OrderStatus, order.status) ===
        OrderStatus.castConfirm
          ? "お店から姫予約の再申請が届いています"
          : "下記の姫予約が確定しました"}
      </NotificationTitle>
      <Box display={"flex"} alignItems={"flex-end"}>
        <Box display={"flex"} flexDirection={"column"} flexBasis={"80%"}>
          <Typography>
            最終受付日:{" "}
            {DateTime.fromJSDate(order.updatedAt).toFormat(
              FORMAT_TYPE.YEAR_DATE_TIME
            )}
          </Typography>
          <Typography>顧客名: {order?.guest?.name || ""}</Typography>
          <Typography>店名: {order?.shop?.name || ""}</Typography>
          <Typography>コース名: {order?.course?.name || ""}</Typography>
          <Typography>住所: {order?.orderAddress || ""}</Typography>
          <Typography>
            出発:{" "}
            {order?.departureTime
              ? DateTime.fromJSDate(order?.departureTime).toFormat(
                  FORMAT_TYPE.YEAR_DATE_TIME
                )
              : ""}
          </Typography>
          <Typography>売上: {order.totalFee}円</Typography>
          <Typography>支給: {order.totalCastFee}円</Typography>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Link to={`/inout/${order.orderId}`} style={{ color: "blue" }}>
              詳細
            </Link>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"} flexBasis={"20%"}>
          {EnumUtils.mapToEnum(OrderStatus, order.status) ===
          OrderStatus.castRequest ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={onClickCancel}
              >
                キャンセル
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "10px" }}
                onClick={onClickApprove}
              >
                承認
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={onClickConfirm}
            >
              確認
            </Button>
          )}
        </Box>
      </Box>
    </NotificationContainer>
  );
};

const NotificationItem = ({
  startDate,
  title,
  content,
  isRead,
}: {
  startDate: string;
  title: string;
  content: string;
  isRead: boolean;
}) => {
  return (
    <NotificationContainer>
      <NotificationTitle>
        {startDate} {title}
      </NotificationTitle>
      <NotificationContent>{content}</NotificationContent>
      <NotificationRead>{isRead ? "既読" : "未読"}</NotificationRead>
    </NotificationContainer>
  );
};

const Top = () => {
  const companyId = useSelector((state) => state.account.cast.companyId);
  const [castMobileMenu, setCastMobileMenu] = useState<any>(
    {} as CastMobileMenuRes
  );
  useEffect(() => {
    const fetchData = async () => {
      const result = await CastMobileMenuApi.findOne(companyId);
      setCastMobileMenu(result);
    };
    fetchData();
  }, [companyId]);
  return (
    <>
      <LinksWrapper>
        {links
          .filter((item) => castMobileMenu[item?.key])
          .map((item) => (
            <React.Fragment key={item.href}>
              <LinkContainer to={item.href}>
                <IconWrapper>
                  <item.icon fontSize="large" />
                </IconWrapper>
                <Label>{item.label}</Label>
              </LinkContainer>
            </React.Fragment>
          ))}
      </LinksWrapper>
      <NotificationList />
    </>
  );
};

export default Top;
