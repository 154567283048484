import { createSlice } from "@reduxjs/toolkit";
import CourseRes from "types/res/course/CourseRes";
import { fetchCourse } from "redux/actions/course";

export type CourseState = CourseRes[];

const initialState = [] as CourseState;

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCourse.fulfilled, (_, { payload }) => payload);
  },
});

export default courseSlice.reducer;
