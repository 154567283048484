import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { Box, IconButton, Select, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "./calendar.css";
import { configureKey, ShiftState } from "redux/reducers/shifts";
import {
  deleteShift,
  fetchShifts,
  postShift,
  putShift,
} from "redux/actions/shifts";
import useAsyncCallback from "hooks/useAsyncCallback";
import toastActions, { ToastType } from "redux/actions/toast";
import {
  Calendar,
  FormContainer,
  TimePickerContainer,
  Spinner,
  SubmitButton,
  FormInner,
} from "./components";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { CalendarTileProperties } from "react-calendar";
import BackdropSpinner from "components/BackdropSpinner";
import ShiftStatus from "../../types/enum/ShiftStatus";
import TimeUtils from "utils/TimeUtils";
import { fetchShops } from "redux/actions/shops";
import { DateTime } from "luxon";
import { Delete } from "@material-ui/icons";

const getTile = ({ shifts = {} }: { shifts: ShiftState }) => {
  const changeDateTime = useSelector(
    (state) => state.account.cast.company.changeDateTime
  );
  const changeDate = changeDateTime
    ? DateTime.fromFormat(changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");

  return ({ date }: CalendarTileProperties) => {
    const time =
      shifts[DateTime.fromJSDate(date).toFormat(FORMAT_TYPE.YEAR_DAY)];
    if (!time) return null;
    return (
      <div>
        <div key={time.castShiftId}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography>
              {0 <= time.planWorkStart.getHours() &&
              time.planWorkStart.getHours() < changeDate.hour
                ? `${time.planWorkStart.getHours() + 24}:${String(
                    time.planWorkStart.getMinutes()
                  ).padStart(2, "0")}`
                : DateTimeUtils.toFormatAsLocalTimezone(
                    time.planWorkStart,
                    FORMAT_TYPE.TIME
                  )}
              〜
            </Typography>
            <Typography>
              {time.planWorkEnd
                ? 0 <= time.planWorkEnd.getHours() &&
                  time.planWorkEnd.getHours() <= changeDate.hour
                  ? `${time.planWorkEnd.getHours() + 24}:${String(
                      time.planWorkEnd.getMinutes()
                    ).padStart(2, "0")}`
                  : DateTimeUtils.toFormatAsLocalTimezone(
                      time.planWorkEnd,
                      FORMAT_TYPE.TIME
                    )
                : "未設定"}
            </Typography>
          </Box>
        </div>
      </div>
    );
  };
};

const configureTextfieldValueFromSelectedDate = (
  selectedDate: Date,
  existingShiftTimestamp?: Date
) => {
  return existingShiftTimestamp
    ? DateTimeUtils.toFormatAsLocalTimezone(
        existingShiftTimestamp,
        "yyyy-MM-dd'T'HH:mm"
      )
    : DateTimeUtils.toFormatAsLocalTimezone(selectedDate, "yyyy-MM-dd'T'HH:mm");
};

const configureTextfieldValueFromSelectedHour = (
  selectedDate: Date,
  existingShiftTimestamp?: Date,
  changeDateTime?: string
) => {
  const changeDate = changeDateTime
    ? DateTime.fromFormat(changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");
  return existingShiftTimestamp
    ? 0 <= existingShiftTimestamp.getHours() &&
      existingShiftTimestamp.getHours() <= changeDate.hour
      ? String(existingShiftTimestamp.getHours() + 24).padStart(2, "0")
      : String(existingShiftTimestamp.getHours()).padStart(2, "0")
    : "";
};

const configureTextfieldValueFromSelectedMinute = (
  selectedDate: Date,
  existingShiftTimestamp?: Date
) => {
  return existingShiftTimestamp
    ? DateTimeUtils.toFormatAsLocalTimezone(existingShiftTimestamp, "mm")
    : "";
};

const Shift = () => {
  const dispatch = useDispatch();
  const [hours, setHours] = useState<string[]>([]);
  const minutes = ["00", "30"];
  const [selectedDate, setSelectedDate] = useState(new Date());
  const cast = useSelector((state) => state.account.cast);
  const shops = useSelector((state) => state.shops);
  const [month, setMonth] = useState(
    DateTimeUtils.toFormatAsLocalTimezone(new Date(), "yyyy-MM")
  );
  const company = useSelector((state) => state.account.cast.company);
  const changeDateTime = useSelector(
    (state) => state.account.cast.company.changeDateTime
  );
  const changeDate = changeDateTime
    ? DateTime.fromFormat(changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");

  const shifts = useSelector((state) => state.shifts);
  const selectedDateShift =
    shifts[
      configureKey(
        DateTime.fromJSDate(selectedDate)
          .plus({ hours: changeDate.hour })
          .toJSDate(),
        company
      )
    ];
  const [startHour, setStartHour] = useState(
    configureTextfieldValueFromSelectedHour(
      selectedDate,
      selectedDateShift?.planWorkStart,
      selectedDateShift?.company?.changeDateTime
    )
  );
  const [startMinute, setStartMinute] = useState(
    configureTextfieldValueFromSelectedMinute(
      selectedDate,
      selectedDateShift?.planWorkStart
    )
  );
  const [endHour, setEndHour] = useState(
    configureTextfieldValueFromSelectedHour(
      selectedDate,
      selectedDateShift?.planWorkEnd,
      selectedDateShift?.company?.changeDateTime
    )
  );
  const [endMinute, setEndMinute] = useState(
    configureTextfieldValueFromSelectedMinute(
      selectedDate,
      selectedDateShift?.planWorkEnd
    )
  );
  const [minOpeningTime, setMinOpeningTime] = useState();
  const [maxClosingTime, setMaxClosingTime] = useState();

  const [startDate, setStartDate] = useState<string>(
    configureTextfieldValueFromSelectedDate(
      selectedDate,
      selectedDateShift?.planWorkStart
    )
  );
  const [endDate, setEndDate] = useState<string>(
    configureTextfieldValueFromSelectedDate(
      selectedDate,
      selectedDateShift?.planWorkEnd
    )
  );
  const selectDateString = DateTime.fromJSDate(selectedDate)
    .startOf("day")
    .plus({ hours: changeDate.hour, minutes: changeDate.minute })
    .toFormat(FORMAT_TYPE.YEAR_DAY);
  const todayString = DateTime.local()
    .startOf("day")
    .plus({ hours: changeDate.hour, minutes: changeDate.minute })
    .toFormat(FORMAT_TYPE.YEAR_DAY);
  const {
    refresh: refreshShifts,
    isLoading: isFetchingShifts,
  } = useAsyncCallback(
    () =>
      dispatch(
        fetchShifts({ companyId: cast.companyId, castId: cast.castId, month })
      ),
    [month, cast]
  );

  const {
    refresh: callPostShift,
    isLoading: isPostingShift,
  } = useAsyncCallback(
    () =>
      dispatch(
        postShift({
          companyId: cast.companyId,
          req: {
            castId: cast.castId,
            shops: cast?.shops?.map((shop) => shop.shopId) || [],
            status: ShiftStatus.unconfirmed,
            planWorkStart: DateTimeUtils.fromISO(startDate).toJSDate(),
            planWorkEnd: DateTimeUtils.fromISO(endDate).toJSDate(),
            castMemo: "",
          },
        })
      ),
    [startDate, endDate, cast]
  );
  const { refresh: callPutShift, isLoading: isPuttingShift } = useAsyncCallback(
    () =>
      dispatch(
        putShift({
          companyId: cast.companyId,
          req: {
            castShiftId: selectedDateShift.castShiftId,
            castId: cast.castId,
            shops: cast?.shops?.map((shop) => shop.shopId) || [],
            status: ShiftStatus.unconfirmed,
            planWorkStart: DateTimeUtils.fromISO(startDate).toJSDate(),
            planWorkEnd: DateTimeUtils.fromISO(endDate).toJSDate(),
            castMemo: "",
          },
        })
      ),
    [startDate, endDate, selectedDateShift, cast]
  );
  const { refresh: callDeleteShift } = useAsyncCallback(
    () =>
      dispatch(
        deleteShift({
          companyId: cast.companyId,
          req: {
            castShiftId: selectedDateShift.castShiftId,
          },
        })
      ),
    [selectedDateShift, cast]
  );

  const onClickSubmit = async () => {
    const res = selectedDateShift
      ? await callPutShift()
      : await callPostShift();
    if (postShift.fulfilled.match(res) || putShift.fulfilled.match(res)) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    } else if (postShift.rejected.match(res) || putShift.rejected.match(res)) {
      dispatch(
        toastActions.showToast({
          text: res.error.message ?? "送信に失敗しました",
          type: ToastType.Failure,
        })
      );
    }
  };

  const onClickDay = (day: any) => {
    const selectedDate = new Date(day);
    setSelectedDate(selectedDate);
  };

  const onActiveStartDateChange = ({ activeStartDate }: any) => {
    const nextMonth = DateTimeUtils.toFormatAsLocalTimezone(
      new Date(activeStartDate),
      "yyyy-MM"
    );
    setMonth(nextMonth);
  };

  const onDeleteShift = async () => {
    const res = await callDeleteShift();
    if (deleteShift.fulfilled.match(res)) {
      dispatch(
        toastActions.showToast({
          text: "送信に成功しました！",
          type: ToastType.Success,
        })
      );
    }
    if (deleteShift.rejected.match(res)) {
      dispatch(
        toastActions.showToast({
          text: "送信に失敗しました！",
          type: ToastType.Success,
        })
      );
    }
  };

  useEffect(() => {
    if (!shops.length) return;
    setMinOpeningTime(TimeUtils.minOpeningTimeInShop(shops));
    setMaxClosingTime(TimeUtils.maxClosingTimeInShop(shops));
  }, [shops]);

  useEffect(() => {
    if (!maxClosingTime && !minOpeningTime) return;
    const timeDiff = TimeUtils.isInAllDays(shops)
      ? maxClosingTime - minOpeningTime
      : maxClosingTime - minOpeningTime + 24;

    const arrayHours = [...Array(timeDiff).keys()].map((a) =>
      String(a + minOpeningTime).padStart(2, "0")
    );
    setHours(arrayHours);
  }, [minOpeningTime, maxClosingTime]);

  useEffect(() => {
    setStartDate(
      configureTextfieldValueFromSelectedDate(
        selectedDate,
        selectedDateShift?.planWorkStart
      )
    );
    setEndDate(
      configureTextfieldValueFromSelectedDate(
        selectedDate,
        selectedDateShift?.planWorkEnd
      )
    );
    setStartHour(
      configureTextfieldValueFromSelectedHour(
        selectedDate,
        selectedDateShift?.planWorkStart,
        selectedDateShift?.company?.changeDateTime
      )
    );
    setEndHour(
      configureTextfieldValueFromSelectedHour(
        selectedDate,
        selectedDateShift?.planWorkEnd,
        selectedDateShift?.company?.changeDateTime
      )
    );
    setStartMinute(
      configureTextfieldValueFromSelectedMinute(
        selectedDate,
        selectedDateShift?.planWorkStart
      )
    );
    setEndMinute(
      configureTextfieldValueFromSelectedMinute(
        selectedDate,
        selectedDateShift?.planWorkEnd
      )
    );
  }, [selectedDateShift, selectedDate]);

  useEffect(() => {
    refreshShifts();
  }, [refreshShifts]);

  useEffect(() => {
    dispatch(fetchShops({ companyId: cast.companyId }));
  }, [cast]);

  useEffect(() => {
    if (!startMinute || !startHour) return;
    const nextDayHours = hours
      .filter((hour) => Number(hour) > 23)
      .map((hour) => Number(hour) - 24);
    setStartDate(
      DateTime.fromJSDate(selectedDate)
        .startOf("day")
        .plus({
          hours: nextDayHours.includes(Number(startHour))
            ? Number(startHour) + 24
            : Number(startHour),
          minutes: Number(startMinute),
        })
        .toISO()
    );
  }, [startMinute, startHour]);

  useEffect(() => {
    if (!endMinute || !endHour) return;
    const nextDayHours = hours
      .filter((hour) => Number(hour) > 23)
      .map((hour) => Number(hour) - 24);
    setEndDate(
      DateTime.fromJSDate(selectedDate)
        .startOf("day")
        .plus({
          hours: nextDayHours.includes(Number(endHour))
            ? Number(endHour) + 24
            : Number(endHour),
          minutes: Number(endMinute),
        })
        .toISO()
    );
  }, [endMinute, endHour]);
  console.log(shifts);
  return (
    <>
      <div>
        <Calendar
          locale="ja"
          next2Label={null}
          prev2Label={null}
          tileContent={getTile({ shifts: shifts })}
          onClickDay={onClickDay}
          onActiveStartDateChange={onActiveStartDateChange}
          defaultValue={selectedDate}
          tileClassName="tileButton"
        />
        <FormContainer>
          <FormInner>
            <div>
              <div>
                {DateTimeUtils.toFormatAsLocalTimezone(
                  selectedDate,
                  "MM/dd (EEEE)"
                )}
              </div>
              <TimePickerContainer>
                <div>開始</div>
                <Box display="flex">
                  <Select
                    native
                    value={startHour}
                    onChange={(event) =>
                      setStartHour(String(event.target.value))
                    }
                  >
                    <option value={""} />
                    {hours.map((hour: string) => (
                      <option
                        key={hour}
                        value={
                          0 <= Number(hour) && Number(hour) <= changeDate.hour
                            ? String(Number(hour) + 24).padStart(2, "0")
                            : hour
                        }
                      >
                        {0 <= Number(hour) && Number(hour) <= changeDate.hour
                          ? String(Number(hour) + 24).padStart(2, "0")
                          : hour}
                      </option>
                    ))}
                  </Select>
                  :
                  <Select
                    native
                    value={startMinute}
                    onChange={(event) =>
                      setStartMinute(String(event.target.value))
                    }
                  >
                    <option value={""} />
                    {minutes.map((minute: string) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </Select>
                </Box>
              </TimePickerContainer>
              <TimePickerContainer>
                <div>終了</div>
                <Box display="flex">
                  <Select
                    native
                    value={endHour}
                    onChange={(event) => setEndHour(String(event.target.value))}
                  >
                    <option value={""} />
                    {hours.map((hour: string) => (
                      <option
                        key={hour}
                        value={
                          0 <= Number(hour) && Number(hour) <= changeDate.hour
                            ? String(Number(hour) + 24).padStart(2, "0")
                            : hour
                        }
                      >
                        {0 <= Number(hour) && Number(hour) <= changeDate.hour
                          ? String(Number(hour) + 24).padStart(2, "0")
                          : hour}
                      </option>
                    ))}
                  </Select>
                  :
                  <Select
                    native
                    value={endMinute}
                    onChange={(event) =>
                      setEndMinute(String(event.target.value))
                    }
                  >
                    <option value={""} />
                    {minutes.map((minute: string) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </Select>
                </Box>
              </TimePickerContainer>
            </div>
            <IconButton
              onClick={() => onDeleteShift()}
              style={{ marginLeft: "8px" }}
              disabled={
                selectDateString === todayString ||
                !selectedDateShift ||
                startDate < todayString
              }
            >
              <Delete />
            </IconButton>
          </FormInner>
          <SubmitButton
            fullWidth
            color="primary"
            variant="contained"
            disabled={
              !startDate ||
              !endDate ||
              (selectDateString === todayString && !!selectedDateShift) ||
              startDate < todayString
            }
            onClick={onClickSubmit}
          >
            {isPostingShift || isPuttingShift ? (
              <Spinner size={15} />
            ) : selectedDateShift ? (
              "変更する"
            ) : (
              "送信する"
            )}
          </SubmitButton>
        </FormContainer>
      </div>
      <BackdropSpinner isLoading={isFetchingShifts} />
    </>
  );
};

export default Shift;
