import RequestUtils from "utils/RequestUtils";
import DriverSettingBookEmailRes from "types/res/driverSettingBookEmail/DriverSettingBookEmailRes";

export default class DriverSettingBookEmailApi {
  private constructor() {}

  static async find(
    companyId: number,
    shopId: number
  ): Promise<DriverSettingBookEmailRes> {
    return await RequestUtils.get(
      DriverSettingBookEmailRes,
      `/driverSettingBookEmail/find/${companyId}/${shopId}`
    );
  }
}
