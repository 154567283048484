import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormLabel,
  Link,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestNotes } from "redux/actions/guestnotes";
import SearchIcon from "@material-ui/icons/Search";
import useModal from "../../hooks/useModal";
import Modal from "components/Modal";
import { Autocomplete } from "@material-ui/lab";

const GuestNoteList = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.cast.companyId);
  const [searchModal, setSearchShow] = useModal("search");
  const guestNotes = useSelector((state) => Object.values(state.guestNotes));
  const [guestNoteList, setGuestNoteList] = useState(guestNotes);
  const [startDate, setStartDate] = useState(
    DateTimeUtils.local()
      .minus({ month: 6 })
      .startOf("month")
      .toFormat(FORMAT_TYPE.YEAR_DAY)
  );
  const [endDate, setEndDate] = useState(
    DateTimeUtils.local().toFormat(FORMAT_TYPE.YEAR_DAY)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectGuest, setSelectGuest] = useState();
  const [selectFilterType, setSelectFilterType] = useState("nickName");
  const onClickSearchButton = () => {
    if (selectGuest) {
      setGuestNoteList(
        guestNotes.filter((guestNote) =>
          selectFilterType === "nickName"
            ? guestNote.nickName === selectGuest?.nickName
            : guestNote?.guest?.name === selectGuest?.name
        )
      );
    }
    setSearchShow(false);
  };
  useEffect(() => {
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(fetchGuestNotes({ companyId, startDate, endDate }));
      setIsLoading(false);
    };
    apiCall();
  }, [dispatch, companyId, startDate, endDate]);

  useEffect(() => {
    setGuestNoteList(guestNotes);
  }, [guestNotes.length]);

  return (
    <>
      <Box padding={2} display="flex" justifyContent="space-between">
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          接客履歴
        </Typography>
        <SearchIcon onClick={() => setSearchShow(true)} />
        <Modal
          show={searchModal?.show || false}
          setShow={setSearchShow}
          title="検索"
        >
          <Box display="flex" flexDirection="column">
            <TextField
              type="date"
              variant="outlined"
              label="最終受付開始日"
              value={startDate}
              style={{ marginBottom: "10px" }}
              onChange={(event) => setStartDate(event.target.value)}
            />
            <TextField
              type="date"
              variant="outlined"
              label="最終受付終了日"
              value={endDate}
              style={{ marginBottom: "10px" }}
              onChange={(event) => setEndDate(event.target.value)}
            />
            <Autocomplete
              fullWidth
              options={guestNotes.map((guestNote) => ({
                id: guestNote.guestId,
                name: guestNote.guest.name,
                nickName: guestNote.nickName,
              }))}
              getOptionLabel={(option: any) =>
                `${option.name || "名前未設定"} (${
                  option.nickName || "あだ名未設定"
                })`
              }
              value={selectGuest || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={"outlined"}
                  label={"顧客(あだ名)"}
                />
              )}
              onChange={(event, value) => {
                setSelectGuest(value || undefined);
              }}
              noOptionsText="選択肢がありません"
            />
            <RadioGroup
              aria-label="gender"
              name="gender1"
              style={{ marginTop: "8px" }}
              value={selectFilterType}
              onChange={(e) => setSelectFilterType(e.target.value)}
            >
              <FormLabel component="legend">検索項目</FormLabel>
              <Box display={"flex"}>
                <FormControlLabel
                  value="nickName"
                  control={<Radio />}
                  label="ニックネーム"
                />
                <FormControlLabel
                  value="name"
                  control={<Radio />}
                  label="名前"
                />
              </Box>
            </RadioGroup>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "16px" }}
              onClick={onClickSearchButton}
            >
              決定
            </Button>
          </Box>
        </Modal>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent={"center"}>
          <CircularProgress />
        </Box>
      ) : guestNoteList.length ? (
        <List>
          {guestNoteList.map((guestNote) => (
            <>
              <ListItem style={{ backgroundColor: "#eaeaea" }}>
                <Box
                  display="flex"
                  flex="1"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" flexDirection="column" flexBasis="80%">
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        最終受付日
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {DateTimeUtils.toFormatAsLocalTimezone(
                          guestNote.orders[0].createdAt,
                          FORMAT_TYPE.YEAR_DATE_TIME
                        )}
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        顧客名(あだ名)
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {guestNote.guest.name}{" "}
                        {guestNote.nickName ? `(${guestNote.nickName})` : ""}
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        店名
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {guestNote.orders[0]?.shop?.name || "未設定"}
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        コース
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {guestNote.orders[0]?.course?.name || "未設定"}
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        住所
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {guestNote.orders[0]?.orderAddress ||
                          guestNote.orders[0]?.hotel?.name ||
                          "未設定"}
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        出発
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {guestNote.orders[0]?.departureTime
                          ? DateTimeUtils.toFormatAsLocalTimezone(
                              guestNote.orders[0].departureTime,
                              FORMAT_TYPE.YEAR_DATE_TIME
                            )
                          : "未設定"}
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        売上
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {guestNote.orders[0].totalFee}円
                      </Typography>
                    </Box>
                    <Box display="flex" marginBottom={0.5}>
                      <Typography style={{ width: "80px", fontWeight: "bold" }}>
                        支給
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {guestNote.orders[0].totalCastFee}円
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Link
                      href={`/guestNote/${guestNote.guestNoteId}`}
                      style={{ marginBottom: "5px" }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        ノート
                      </Typography>
                    </Link>
                    <Link href={`/orderHistory/${guestNote.guestNoteId}`}>
                      <Typography style={{ fontWeight: "bold" }}>
                        利用履歴
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      ) : (
        <Box display="flex" justifyContent="center" padding={2}>
          <Typography style={{ fontWeight: "bold" }}>
            接客履歴がありません
          </Typography>
        </Box>
      )}
    </>
  );
};
export default GuestNoteList;
