import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders } from "redux/actions/orders";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "../../utils/DateTimeUtils";
import { useParams } from "react-router-dom";
import { fetchOneGuestNote } from "redux/actions/guestnotes";
import OrderRes from "types/res/order/OrderRes";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.cast.companyId);
  const castId = useSelector((state) => state.account.cast.castId);
  const { id } = useParams();
  const guestNote = useSelector((state) => state.guestNotes)[0];
  useEffect(() => {
    if (!id) return;
    dispatch(
      fetchOneGuestNote({ companyId: companyId, guestNoteId: Number(id) })
    );
  }, [dispatch, id]);
  const startDate = DateTimeUtils.toFormatAsLocalTimezone(
    DateTime.local().minus({ months: 6 }).toJSDate(),
    FORMAT_TYPE.YEAR_DAY
  );
  const endDate = DateTimeUtils.toFormatAsLocalTimezone(
    DateTime.local().toJSDate(),
    FORMAT_TYPE.YEAR_DAY
  );
  const orders = useSelector((state) => state.orders);
  const [filterOrders, setFilterOrders] = useState<OrderRes[]>([]);
  useEffect(() => {
    console.log(orders.find((order) => order.orderId === 1247293));
    setFilterOrders(
      orders.filter((order) => order.guestId === guestNote?.guestId)
    );
  }, [guestNote, orders]);
  useEffect(() => {
    dispatch(fetchOrders({ companyId, castId, startDate, endDate }));
  }, [companyId, castId, startDate, endDate]);
  return (
    <>
      <Box padding={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {filterOrders[0]?.guest?.name || "未設定"}様利用履歴
          </Typography>
          {filterOrders[0]?.guestId && (
            <Link href={`/guestNote/${guestNote?.guestNoteId}`}>
              <Typography style={{ fontWeight: "bold" }}>ノート</Typography>
            </Link>
          )}
        </Box>
      </Box>
      <List>
        {filterOrders.map((order) => (
          <>
            <ListItem style={{ backgroundColor: "#eaeaea" }}>
              <Box
                display="flex"
                flex="1"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column" flexBasis="80%">
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      受付日
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.planInTime
                        ? DateTimeUtils.toFormatAsLocalTimezone(
                            order.planInTime,
                            FORMAT_TYPE.YEAR_DATE_TIME
                          )
                        : ""}
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      店名
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.shop?.name || "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      コース
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.course?.name || "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      延長
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.additionalTime && order?.additionalTimeCount
                        ? `${
                            order?.additionalTime.time *
                            order.additionalTimeCount
                          }分`
                        : "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      オプション
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.options
                        ?.map((option) => option?.name)
                        ?.join("/") || "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      コスプレ
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.cosplay?.name || "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      住所
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order?.orderAddress || order?.hotel?.name || "未設定"}
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      売上
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order.totalFee}円
                    </Typography>
                  </Box>
                  <Box display="flex" marginBottom={0.5}>
                    <Typography style={{ width: "80px", fontWeight: "bold" }}>
                      支給
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {order.totalCastFee}円
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
};

export default OrderHistory;
