import { Expose, Type } from "class-transformer";
import CompanyRes from "../company/CompanyRes";
import { PlanType } from "types/enum/PlanType";

export default class CompanyGroupRes {
  @Expose() readonly companyGroupId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly kana!: string;
  @Expose() readonly plan!: PlanType;
  @Type(() => CompanyRes)
  @Expose()
  readonly companies!: CompanyRes[];
}
