import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import NotificationType from "../../enum/NotificationType";
import NotificationReadRes from "../notificationRead/NotificationReadRes";

export default class NotificationRes {
  @Expose() readonly notificationId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly title!: string;
  @Expose() readonly content!: string;
  @Expose() readonly type!: NotificationType;
  @Type(() => Date)
  @Expose()
  readonly startDate!: Date | null;
  @Type(() => Date)
  @Expose()
  readonly endDate!: Date | null;
  @Expose()
  readonly isIndefinite!: boolean | null;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
  @Type(() => NotificationReadRes)
  @Expose()
  readonly notificationReads!: NotificationReadRes[];
}
