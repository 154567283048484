import RequestUtils from "../utils/RequestUtils";
import AreaRes from "../types/res/area/AreaRes";

export default class AreaApi {
  static async fetchArea(companyId: number, shopId: number) {
    return await RequestUtils.getArray(
      AreaRes,
      `/area/findAll/${companyId}/${shopId}`
    );
  }
}
