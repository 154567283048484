import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";

export default class MediumRes {
  @Expose() readonly mediumId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly totalFee!: number;
  @Expose() readonly shopFee!: number;
  @Expose() readonly castFee!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shops!: ShopRes[];
}
