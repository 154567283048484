import ShopRes from "types/res/shop/ShopRes";

export default class TimeUtils {
  private constructor() {
    //   do nothing
  }
  static isInAllDays(shops: ShopRes[]) {
    return shops
      .map((shop) => shop.closingTime)
      .every(
        (closingTime) =>
          12 <= Number(closingTime.split(":")[0]) &&
          Number(closingTime.split(":")[0]) <= 24
      );
  }
  static isInDays(shops: ShopRes[]) {
    return shops
      .map((shop) => shop.closingTime)
      .some(
        (closingTime) =>
          12 <= Number(closingTime.split(":")[0]) &&
          Number(closingTime.split(":")[0]) <= 24
      );
  }

  static minOpeningTimeInShop(shops: ShopRes[]) {
    return Number(
      shops.reduce(
        (a, b) =>
          a < Number(b.openingTime.split(":")[0])
            ? a
            : Number(b.openingTime.split(":")[0]),
        24
      )
    );
  }

  static maxClosingTimeInShop(shops: ShopRes[]) {
    return this.isInAllDays(shops)
      ? Number(
          shops.reduce(
            (a, b) =>
              a > Number(b.closingTime.split(":")[0])
                ? a
                : Number(b.closingTime.split(":")[0]),
            0
          )
        ) + 1
      : this.isInDays(shops)
      ? Number(
          shops.reduce(
            (a, b) =>
              a < Number(b.closingTime.split(":")[0])
                ? a
                : Number(b.closingTime.split(":")[0]),
            25
          )
        ) + 1
      : Number(
          shops.reduce(
            (a, b) =>
              a > Number(b.closingTime.split(":")[0])
                ? a
                : Number(b.closingTime.split(":")[0]),
            0
          )
        ) + 1;
  }
}
