import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, CheckCircle } from "@material-ui/icons";

import ShiftStatus from "types/enum/ShiftStatus";
import EnumUtils from "../../utils/EnumUtils";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { fetchShifts, updateRestTimeShift } from "redux/actions/shifts";
import toastActions, { ToastType } from "redux/actions/toast";
import useAsyncCallback from "hooks/useAsyncCallback";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  height: 100%;
  > * {
    margin-bottom: 24px;
  }
`;

export const DateTimeContainer = styled.div`
  text-align: center;
  font-size: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;

const DateChangerContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
`;

const DateChangerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerifiedContainer = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ShiftContent = ({
  month,
  currentDate,
  message,
  onApproveShift,
  companyTel,
  onChangeMessage,
  onClickSendMessage,
  shift,
}: any) => {
  const dispatch = useDispatch();
  const cast = useSelector((state) => state.account.cast);
  const changeDateTime = useSelector(
    (state) => state.account.cast.company.changeDateTime
  );
  const changeDate = changeDateTime
    ? DateTime.fromFormat(changeDateTime, "HH:mm:ss")
    : DateTime.fromFormat("00:00:00", "HH:mm:ss");
  const verified =
    ShiftStatus.verified === EnumUtils.mapToEnum(ShiftStatus, shift?.status);
  const [startTime, setStartTime] = useState({ hour: "", minute: "" });
  const [endTime, setEndTime] = useState({ hour: "", minute: "" });

  useEffect(() => {
    if (!shift) return;
    const startHour = String(
      shift.workOffStart?.getHours() > 8
        ? shift.workOffStart?.getHours()
        : shift.workOffStart?.getHours() + 24
    ).padStart(2, "0");
    const startMinute = String(shift.workOffStart?.getMinutes()).padStart(
      2,
      "0"
    );
    const endHour = String(
      shift.workOffEnd?.getHours() > 8
        ? shift.workOffEnd?.getHours()
        : shift.workOffEnd?.getHours() + 24
    ).padStart(2, "0");
    const endMinute = String(shift.workOffEnd?.getMinutes()).padStart(2, "0");
    setStartTime({ hour: startHour, minute: startMinute });
    setEndTime({ hour: endHour, minute: endMinute });
  }, [shift]);

  const {
    refresh: refreshShifts,
    isLoading: isFetchingShifts,
  } = useAsyncCallback(
    () =>
      dispatch(
        fetchShifts({ companyId: cast.companyId, castId: cast.castId, month })
      ),
    [month, cast]
  );

  if (!shift) return <div>シフトがありません</div>;
  const endShiftHour =
    DateTime.fromJSDate(shift.planWorkEnd).toFormat(FORMAT_TYPE.YEAR_DAY) ===
      currentDate.toFormat(FORMAT_TYPE.YEAR_DAY) &&
    shift.planWorkEnd.getHours() > 12
      ? shift.planWorkEnd.getHours()
      : shift.planWorkEnd.getHours() + 24;
  const shiftHourArray = new Array(
    endShiftHour - shift.planWorkStart.getHours() + 1
  )
    .fill(0)
    .map((_, index) =>
      String(index + shift.planWorkStart.getHours()).padStart(2, "0")
    );

  const shiftMinuteArray = new Array(60)
    .fill(0)
    .map((_, index) => String(index).padStart(2, "0"));

  const onClickSaveBreakTime = async () => {
    const workOffStart = currentDate
      .plus({
        hours: Number(startTime.hour) - 9,
        minutes: Number(startTime.minute),
      })
      .toFormat(FORMAT_TYPE.YEAR_DATE_TIME);
    const workOffEnd = currentDate
      .plus({
        hours: Number(endTime.hour) - 9,
        minutes: Number(endTime.minute),
      })
      .toFormat(FORMAT_TYPE.YEAR_DATE_TIME);
    if (workOffStart > workOffEnd) {
      alert("休憩時間が不正です");
      return;
    }
    await dispatch(
      updateRestTimeShift({
        companyId: cast.companyId,
        castShiftId: shift.castShiftId,
        startTime: workOffStart,
        endTime: workOffEnd,
      })
    );
    await refreshShifts();
    await dispatch(
      toastActions.showToast({
        text: "送信に成功しました！",
        type: ToastType.Success,
      })
    );
  };

  return (
    <>
      <DateTimeContainer>
        {0 <= shift.planWorkStart.getHours() &&
        shift.planWorkStart.getHours() <= changeDate.hour
          ? shift.planWorkStart.getHours() + 24
          : shift.planWorkStart.getHours()}
        : {String(shift.planWorkStart.getMinutes()).padStart(2, "0")} -
        {0 <= shift.planWorkEnd.getHours() &&
        shift.planWorkEnd.getHours() <= changeDate.hour
          ? shift.planWorkEnd.getHours() + 24
          : shift.planWorkEnd.getHours()}
        : {String(shift.planWorkEnd.getMinutes()).padStart(2, "0")}
      </DateTimeContainer>
      {verified && (
        <VerifiedContainer>
          <CheckCircle style={{ color: "green" }} /> 確認済み
        </VerifiedContainer>
      )}
      <ButtonsContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={onApproveShift}
          disabled={verified}
        >
          予定通り出勤
        </Button>
        <Button variant="contained" color="primary" href={`tel:${companyTel}`}>
          遅刻/お休みの連絡　☎
        </Button>
      </ButtonsContainer>
      <Box display={"flex"} flexDirection={"column"}>
        <Typography>休憩時間</Typography>
        <Box display={"flex"}>
          <Select
            value={startTime.hour}
            onChange={(event) =>
              setStartTime((prev) => ({
                ...prev,
                hour: event.target.value as string,
              }))
            }
          >
            {shiftHourArray.map((shiftHour) => (
              <MenuItem key={shiftHour} value={shiftHour}>
                {shiftHour}
              </MenuItem>
            ))}
          </Select>
          :
          <Select
            value={startTime.minute}
            onChange={(event) =>
              setStartTime((prev) => ({
                ...prev,
                minute: event.target.value as string,
              }))
            }
          >
            {shiftMinuteArray.map((shiftMinute) => (
              <MenuItem key={shiftMinute} value={shiftMinute}>
                {shiftMinute}
              </MenuItem>
            ))}
          </Select>{" "}
          ~
          <Select
            value={endTime.hour}
            onChange={(event) =>
              setEndTime((prev) => ({
                ...prev,
                hour: event.target.value as string,
              }))
            }
          >
            {shiftHourArray.map((shiftHour) => (
              <MenuItem key={shiftHour} value={shiftHour}>
                {shiftHour}
              </MenuItem>
            ))}
          </Select>
          :
          <Select
            value={endTime.minute}
            onChange={(event) =>
              setEndTime((prev) => ({
                ...prev,
                minute: event.target.value as string,
              }))
            }
          >
            {shiftMinuteArray.map((shiftMinute) => (
              <MenuItem key={shiftMinute} value={shiftMinute}>
                {shiftMinute}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ marginLeft: "12px" }}
            onClick={() => onClickSaveBreakTime()}
          >
            保存
          </Button>
        </Box>
      </Box>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        rows={5}
        value={message}
        onChange={onChangeMessage}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onClickSendMessage}
        disabled={!message}
      >
        メッセージ送信
      </Button>
    </>
  );
};

export const DateChanger = ({ onClickPreviousDay, onClickNextDay }: any) => {
  return (
    <DateChangerContainer>
      <DateChangerButtonContainer>
        <Button variant="contained" onClick={onClickPreviousDay}>
          <ArrowBack />
        </Button>
        <div>前日</div>
      </DateChangerButtonContainer>
      <DateChangerButtonContainer>
        <Button variant="contained" onClick={onClickNextDay}>
          <ArrowForward />
        </Button>
        <div>翌日</div>
      </DateChangerButtonContainer>
    </DateChangerContainer>
  );
};
