import RequestUtils from "../utils/RequestUtils";
import GuestRes from "../types/res/guest/GuestRes";

export default class GuestApi {
  static async fetchOneGuest(companyId: number, guestId: number) {
    return await RequestUtils.get(
      GuestRes,
      `/guest/findOne/${companyId}/${guestId}`
    );
  }
}
