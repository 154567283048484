import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";

export default class SettingBookEmailRes {
  @Expose() readonly settingBookEmailId!: number;
  @Expose() readonly companyId!: number;
  @Expose() readonly isOrderDateEnabled!: boolean;
  @Expose() readonly isOrderCreatedByEnabled!: boolean;
  @Expose() readonly isOrderUpdatedByEnabled!: boolean;
  @Expose() readonly isGuestCategoryEnabled!: boolean;
  @Expose() readonly isGuestNameEnabled!: boolean;
  @Expose() readonly isGuestTelEnabled!: boolean;
  @Expose() readonly isGuestMemoEnabled!: boolean;
  @Expose() readonly isShopNameEnabled!: boolean;
  @Expose() readonly isCastNameEnabled!: boolean;
  @Expose() readonly isCastDesignateCountEnabled!: boolean;
  @Expose() readonly isCastClassNameEnabled!: boolean;
  @Expose() readonly isCastDesignateNameEnabled!: boolean;
  @Expose() readonly isCourseNameEnabled!: boolean;
  @Expose() readonly isAdditionalTimeEnabled!: boolean;
  @Expose() readonly isTotalTimeEnabled!: boolean;
  @Expose() readonly isDiscountNameEnabled!: boolean;
  @Expose() readonly isOptionNameEnabled!: boolean;
  @Expose() readonly isCosplayNameEnabled!: boolean;
  @Expose() readonly isAreaEnabled!: boolean;
  @Expose() readonly isHotelEnabled!: boolean;
  @Expose() readonly isOrderAddressEnabled!: boolean;
  @Expose() readonly isOrderDepartureTimeEnabled!: boolean;
  @Expose() readonly isOrderPlanInTimeEnabled!: boolean;
  @Expose() readonly isOrderActualInTimeEnabled!: boolean;
  @Expose() readonly isOrderActualEndTimeEnabled!: boolean;
  @Expose() readonly isOrderMemoEnabled!: boolean;
  @Expose() readonly isOrderPaymentTypeEnabled!: boolean;
  @Expose() readonly isCarInfoEnabled!: boolean;
  @Expose() readonly shopId!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
}
