import React, { useState } from "react";
import styled from "styled-components";
import { CssBaseline, Drawer } from "@material-ui/core";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { useSelector } from "react-redux";

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  background-color: #e4e4e4;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 414px;
`;

const MainContent = styled.div`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
`;

const Main: React.FC = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isLoggedIn = useSelector((state) => Boolean(state.account.accessToken));
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      {isLoggedIn && (
        <>
          <CssBaseline />
          <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
            <Sidebar />
          </Drawer>
        </>
      )}
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent>{children}</MainContent>
      </AppContent>
    </Root>
  );
};

export default Main;
