import React from "react";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button } from "@material-ui/core";

import { Switcher } from "./components";

const OrderSwitcher = ({
  onClickBackButton,
  onClickForwardButton,
  disabledBackButton = false,
  disabledForwardButton = false,
}: {
  onClickBackButton: () => void;
  onClickForwardButton: () => void;
  disabledBackButton?: boolean;
  disabledForwardButton?: boolean;
}) => {
  return (
    <Switcher>
      <Button
        size="small"
        disabled={disabledBackButton}
        onClick={onClickBackButton}
        startIcon={<NavigateBeforeIcon />}
        variant="contained"
      >
        前
      </Button>
      <Button
        disabled={disabledForwardButton}
        onClick={onClickForwardButton}
        endIcon={<NavigateNextIcon />}
        variant="contained"
        size="small"
      >
        後
      </Button>
    </Switcher>
  );
};

export default OrderSwitcher;
