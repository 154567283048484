import RequestUtils from "../utils/RequestUtils";
import AdditionalTimeRes from "../types/res/additionalTime/AdditionalTimeRes";

export default class AdditionalTimeApi {
  static async fetchAdditionalTime(companyId: number, shopId: number) {
    return await RequestUtils.getArray(
      AdditionalTimeRes,
      `/additionalTime/findAll/${companyId}/${shopId}`
    );
  }
}
