import { Expose, Type } from "class-transformer";
import GuestRes from "../guest/GuestRes";
import GuestRank from "../../enum/GuestRank";
import CastNameRes from "../castName/CastNameRes";
import CastRes from "../cast/CastRes";
import OrderRes from "../order/OrderRes";

export default class GuestNoteRes {
  @Expose() readonly guestNoteId!: number;
  @Expose() readonly nickName!: string;
  @Expose() readonly work!: string;
  @Expose() readonly activityArea!: string;
  @Expose() readonly pet!: string;
  @Expose() readonly cigarette!: string;
  @Expose() readonly home!: string;
  @Expose() readonly favoriteType!: string;
  @Expose() readonly favoriteColor!: string;
  @Expose() readonly favoriteFood!: string;
  @Expose() readonly usingFrequency!: string;
  @Expose() readonly allergies!: string;
  @Expose() readonly personality!: string;
  @Expose() readonly bodyType!: string;
  @Expose() readonly address!: string;
  @Expose() readonly favoritePlay!: string;
  @Expose() readonly genitalSize!: string;
  @Expose() readonly genitalState!: string;
  @Expose() readonly playNum!: string;
  @Expose() readonly erogenousZone!: string;
  @Expose() readonly request!: string;
  @Expose() readonly remarks!: string;
  @Type(() => Date)
  @Expose()
  readonly createdAt!: Date;
  @Expose()
  readonly guestRank!: GuestRank;

  @Type(() => Date)
  @Expose()
  readonly birthday!: Date | null;

  @Expose()
  readonly guestId!: number;
  @Type(() => GuestRes)
  @Expose()
  readonly guest!: GuestRes;

  @Expose()
  readonly castId!: number;
  @Type(() => CastRes)
  @Expose()
  readonly cast!: CastRes;

  @Expose()
  readonly castNameId!: number;
  @Type(() => CastNameRes)
  @Expose()
  readonly castName!: CastNameRes;

  @Type(() => OrderRes)
  @Expose()
  readonly orders!: OrderRes[];
}
