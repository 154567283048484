import React from "react";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  OrdersWrapper,
  OrderItemContainer,
  Title,
  Content,
} from "./components";
import { Order } from "redux/reducers/orders";
import EnumUtils from "utils/EnumUtils";
import PaymentType from "types/enum/PaymentType";

const OrderItem = ({
  title,
  content,
}: {
  title: string;
  content: string | number;
}) => {
  return (
    <OrderItemContainer>
      <Title>【{title}】</Title>
      <Content>{content}</Content>
    </OrderItemContainer>
  );
};

const OrderInfo = ({ order }: { order: Order }) => {
  return (
    <OrdersWrapper>
      <OrderItem
        title="受付日"
        content={DateTimeUtils.toFormatAsLocalTimezone(
          order.orderDate,
          "yyyy-MM-dd"
        )}
      />
      <OrderItem title="店舗名" content={order?.shop?.name || ""} />
      <OrderItem title="顧客名" content={order?.guest?.name || ""} />
      <OrderItem title="源氏名" content={order?.castName?.name || ""} />
      <OrderItem title="コース" content={order?.course.name || ""} />
      <OrderItem title="指名" content={order?.nomination?.name || ""} />
      <OrderItem title="合計時間" content={`${order?.totalTime || ""}分`} />
      <OrderItem title="住所" content={order?.orderAddress || ""} />
      <OrderItem title="住所2" content={order?.orderAddress2 || ""} />
      <OrderItem
        title="送り"
        content={`${order.outwardDriver?.carType || ""} ${
          order.outwardDriver?.carColor || ""
        } ${order.outwardDriver?.carNumber || ""}`}
      />
      <OrderItem
        title="迎え"
        content={`${order.returnDriver?.carType || ""} ${
          order.returnDriver?.carColor || ""
        } ${order.returnDriver?.carNumber || ""}`}
      />
      <OrderItem title="エリア" content={order?.area?.name || ""} />
      <OrderItem title="ホテル" content={order?.hotel?.name || ""} />
      <OrderItem
        title="出発予定"
        content={
          order.departureTime
            ? DateTimeUtils.toFormatAsLocalTimezone(
                order.departureTime,
                FORMAT_TYPE.YEAR_DATE_TIME
              )
            : ""
        }
      />
      <OrderItem
        title="予定IN"
        content={
          order.planInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(
                order.planInTime,
                FORMAT_TYPE.YEAR_DATE_TIME
              )
            : ""
        }
      />
      <OrderItem
        title="予定OUT"
        content={
          order.planOutTime
            ? DateTimeUtils.toFormatAsLocalTimezone(
                order.planOutTime,
                FORMAT_TYPE.YEAR_DATE_TIME
              )
            : ""
        }
      />
      <OrderItem
        title="実IN"
        content={
          order.actualInTime
            ? DateTimeUtils.toFormatAsLocalTimezone(
                order.actualInTime,
                FORMAT_TYPE.YEAR_DATE_TIME
              )
            : ""
        }
      />
      <OrderItem
        title="実OUT"
        content={
          order.actualEndTime
            ? DateTimeUtils.toFormatAsLocalTimezone(
                order.actualEndTime,
                FORMAT_TYPE.YEAR_DATE_TIME
              )
            : ""
        }
      />
      <OrderItem
        title="オプション"
        content={order?.options?.map((option) => option.name).join(" / ")}
      />
      <OrderItem
        title="支払い方法"
        content={EnumUtils.mapToEnum(PaymentType, order.paymentType) || "現金"}
      />
      <OrderItem title="コスプレ" content={order?.cosplay?.name || ""} />
      <OrderItem title="総額" content={`${order.totalFee || 0}円`} />
      <OrderItem title="備考" content={order.memo || ""} />
    </OrdersWrapper>
  );
};

export default OrderInfo;
