import { Expose, Type } from "class-transformer";
import ShopRes from "../shop/ShopRes";
import StaffRes from "../staff/StaffRes";
import NominationRes from "../nomination/NominationRes";
import NotelClassRes from "../notelClass/NotelClassRes";

export default class CourseRes {
  @Expose() readonly courseId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly time!: number;
  @Expose() readonly timeFee!: number;
  @Expose() readonly timeShopFee!: number;
  @Expose() readonly timeCastFee!: number;
  @Expose() readonly welfareFee!: number;
  @Expose() readonly hotelFee!: number | null;
  @Expose() readonly hotelCastFee!: number | null;
  @Expose() readonly hotelShopFee!: number | null;
  @Expose() readonly totalFee!: number;
  @Expose() readonly sort!: number | null;
  @Type(() => NominationRes)
  @Expose()
  readonly nomination!: NominationRes;
  @Expose()
  readonly nominationId!: number;
  @Type(() => NotelClassRes)
  @Expose()
  readonly notelClass!: NotelClassRes;
  @Expose()
  readonly notelClassId!: number;
  @Type(() => ShopRes)
  @Expose()
  readonly shop!: ShopRes;
  @Expose()
  readonly shopId!: number;
  @Expose() readonly companyId!: number;
  @Type(() => StaffRes)
  @Expose()
  readonly updatedBy!: StaffRes;
  @Expose()
  readonly updatedById!: number;
  @Type(() => Date)
  @Expose()
  readonly updatedAt!: Date;
}
