import { createSlice } from "@reduxjs/toolkit";
import HotelRes from "types/res/hotel/HotelRes";
import { fetchHotel } from "redux/actions/hotel";

export type HotelState = HotelRes[];

const initialState = [] as HotelState;

const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHotel.fulfilled, (_, { payload }) => payload);
  },
});

export default hotelSlice.reducer;
