import { createSlice } from "@reduxjs/toolkit";
import NotelClassRes from "types/res/notelClass/NotelClassRes";
import { fetchNotelClassByCastNameId } from "redux/actions/notelClass";

export type NotelClassState = NotelClassRes;

const initialState = {} as NotelClassState;

const notelClassSlice = createSlice({
  name: "notelClass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchNotelClassByCastNameId.fulfilled,
      (_, { payload }) => payload
    );
  },
});

export default notelClassSlice.reducer;
