import { createSlice } from "@reduxjs/toolkit";
import NominationRes from "types/res/nomination/NominationRes";
import { fetchNomination } from "redux/actions/nomination";

export type NominationState = NominationRes[];

const initialState = [] as NominationState;

const nominationSlice = createSlice({
  name: "nomination",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNomination.fulfilled, (_, { payload }) => payload);
  },
});

export default nominationSlice.reducer;
