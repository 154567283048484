import { Expose, Type } from "class-transformer";
import GuestRes from "../guest/GuestRes";

export default class GuestCategoryRes {
  @Expose() readonly guestCategoryId!: number;
  @Expose() readonly name!: string;
  @Type(() => GuestRes)
  @Expose()
  readonly guests!: GuestRes[];
}
