import { Box, MenuItem, TextField, Typography } from "@material-ui/core";
import React from "react";
type BasicInfoProps = {
  title: string;
  value: string;
};
export const GuestNoteBasicInfo: React.FC<BasicInfoProps> = ({
  title,
  value,
}) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
        {title}
      </Typography>
      <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
        {value}
      </Typography>
    </Box>
  );
};

type TextProps = {
  title: string;
  value: string;
  onChange: any;
};
export const GuestNoteTextInput: React.FC<TextProps> = ({
  title,
  value,
  onChange,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      marginBottom={2}
    >
      <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
      <TextField
        onChange={onChange}
        value={value}
        variant="outlined"
        placeholder={`${title}を入力...`}
        style={{ backgroundColor: "white" }}
        multiline={true}
      />
    </Box>
  );
};

type DateProps = {
  title: string;
  value: string;
  onChange: any;
};
export const GuestNoteDateInput: React.FC<DateProps> = ({
  title,
  value,
  onChange,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      marginBottom={2}
    >
      <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
      <TextField
        type="date"
        onChange={onChange}
        value={value}
        variant="outlined"
        style={{ backgroundColor: "white" }}
      />
    </Box>
  );
};

type SelectProps = {
  title: string;
  value: string;
  onChange: any;
  selectProps: { id: string; name: string }[];
};
export const GuestNoteSelectInput: React.FC<SelectProps> = ({
  title,
  value,
  onChange,
  selectProps,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      marginBottom={2}
    >
      <Typography style={{ fontWeight: "bold" }}>{title}</Typography>
      <TextField
        select
        onChange={onChange}
        value={value}
        variant="outlined"
        style={{ backgroundColor: "white" }}
      >
        {selectProps.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
