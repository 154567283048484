import ShiftRes from "types/res/shift/ShiftRes";
import RequestUtils from "../utils/RequestUtils";
import CreateCastShiftReq from "../types/req/shift/CreateCastShiftReq";
import UpdateCastShiftReq from "../types/req/shift/UpdateCastShiftReq";
import PostCastShiftMessageReq from "types/req/shift/PostCastShiftMessageReq";
import DeleteCastShiftReq from "types/req/shift/DeleteCastShiftReq";

export default class ShiftApi {
  static async fetchShifts(
    companyId: number,
    castId: number,
    month: string
  ): Promise<ShiftRes[]> {
    return await RequestUtils.getArray(
      ShiftRes,
      `/shift/findAll/${companyId}/${castId}/${month}`
    );
  }

  static async fetchShiftsByDate(
    companyId: number,
    castId: number,
    startDate: string,
    endDate: string
  ): Promise<ShiftRes[]> {
    return await RequestUtils.getArray(
      ShiftRes,
      `/shift/findAllByDate/${companyId}/${castId}/${startDate}/${endDate}`
    );
  }

  static async postShift(companyId: number, req: CreateCastShiftReq) {
    return await RequestUtils.post(ShiftRes, `/shift/create/${companyId}`, req);
  }

  static async putShift(companyId: number, req: UpdateCastShiftReq) {
    return await RequestUtils.post(ShiftRes, `/shift/update/${companyId}`, req);
  }

  static async postShiftMessage(
    companyId: number,
    req: PostCastShiftMessageReq
  ) {
    return await RequestUtils.post(
      ShiftRes,
      `/shift/message/${companyId}`,
      req
    );
  }

  static async deleteShift(companyId: number, req: DeleteCastShiftReq) {
    return await RequestUtils.postVoid(`/shift/delete/${companyId}`, req);
  }

  static async checkOrders(companyId: number, castId: number, date: string) {
    return await RequestUtils.getPlain(
      `/shift/checkOrders/${companyId}/${castId}/${date}`
    );
  }

  static async postApproveShift(companyId: number, castShiftId: number) {
    return await RequestUtils.post(
      ShiftRes,
      `/shift/approve/${companyId}/${castShiftId}`,
      {}
    );
  }

  static async postUpdateRestTimeShift(
    companyId: number,
    castShiftId: number,
    startTime: string,
    endTime: string
  ) {
    return await RequestUtils.post(
      ShiftRes,
      `/shift/updateRestTime/${companyId}/${castShiftId}`,
      { startTime, endTime }
    );
  }
}
